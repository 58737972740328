html {}

body {
    font-family: 'Gotham', Helvetica, Arial, sans-serif;
    background-color: #f3eff0;
    min-height: 100vh;
}

ol,
ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

a,
a:hover,
a:visited,
a:active {
    color: white;
}

p {
    font-weight: lighter;
}

.italic {
    font-style: italic;
}

.bold {
    font-weight: bold;
}

.vertical-align-middle {
    vertical-align: middle;
}

.content {
    margin-top: 90px;
    min-height: calc(100vh - 156px);
}

.auto-height {
    height: auto !important;
}

.align-center {
    text-align: center;
}

.larger-font {
    font-size: 20px !important;
}


.intern-imgContainer22 {
    display: flex;
    justify-content: center;
}

.contentContainer {
    position: relative;
    height: 200px
}

.contentText-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.contentText-center i {
    font-size: medium;
}

.container.internship-content {
    /* width: 47%; */
    display: flex;
}

.intern-img {
    width: auto;
}

.intern-contactInfo {
    position: absolute;
    text-align: center;
}

.email {
    margin: 0;
}


.internship-header {
    margin-top: 3rem;
}

.opportunities-content {
    margin: 2rem;
    border: 2px solid #FFC000;
    padding: 3rem;
}

@media screen and (min-width: 1024px) {
    .intern-imgContainer22 {
        padding: 2em 0;
        width: 100%;
        position: relative;
        max-width: 1170px;
        margin: auto;
    }

    .intern-img {
        max-width: 100%;
        display: block;
        height: auto;
    }

    .intern-contactInfo {
        width: 437px;
        bottom: 7px;
        left: 65px;
        font-size: 15px;
    }
}

.internships-ul {
    font-size: 16px;
}

.internship-ul li {
    list-style: disc;
}

.internship-h4 {
    text-align: center;
    color: #7030A0;
    font-weight: 700;
    font-size: 21px;
}

.about-us-p {
    margin: .8em 0;
    font-size: 16px;
    line-height: 1.7;
    text-align: justify;
    font-weight: lighter;
}

.about-us-p.first-sentence {
    margin: 0;
}

.about-us-p.first-text {
    text-align: center;
    margin: 0;
}

.about-us-p.center {
    text-align: center;
}

.about-us-left-p {
    margin: .8em 0;
    font-size: 16px;
    line-height: 1.7;
    text-align: left;
    font-weight: lighter;
}

.about-us-left-p a {
    color: blue;
    text-decoration: underline;
}

a.home-page-a-email {
    color: blue;
    text-decoration: underline;

}

a.home-page-a-careers {
    font-weight: 600;
    color: blue;
    text-decoration: underline;
}

.opportunities-row {
    width: 61%;
    margin: 3rem auto;

}

.col-sm-6.right-col {
    padding-right: 2rem;
}

.center-this {
    margin: 0px auto;
    width: 200px;
}

.about-us-a {
    color: black !important;
    text-decoration: underline;
}

.about-us-img {
    max-width: 100%;
}

.about-us-text-container {
    margin-bottom: 30px;
}

.featured-logo-row {
    display: flex;
    align-items: center;
}

.about-us-img-background {
    text-align: center;
}

.about-us-featured-logo {
    max-width: 100%;
    max-height: 150px;
    padding: 15px;
    transition: all .3s ease-in-out;
}

.about-us-featured-logo:hover {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.about-us-custom-logo {
    max-width: 100%;
    transition: all .3s ease-in-out;
}

.about-us-custom-logo:hover {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.end-article {
    margin: 0 auto;
    width: 94%;
}

.networks-p {
    margin: .8em 0;
    font-size: 15px;
    line-height: 1.5;
    text-align: justify;
}

.home-page-a {
    color: black;
}

.home-page-p {
    margin: .8em 0;
    font-size: 16px;
    line-height: 1.7;
}

.home-page-a:hover,
.home-page-a:visited {
    color: black;
}

.home-background {
    /*background-color: #f3eff0;*/
}

.home-page-news-date {
    margin-bottom: 0px;
    font-weight: bold;
}

.newsfeed-event {
    transition: all .3s ease-in-out;
}

.newsfeed-event:hover {
    font-size: 15px;
}

.hc-footer {
    padding-bottom: 20px;
    padding-top: 20px;
    background-color: #582C83;
}

.footer-logo {
    max-height: 70px;
    margin: 0 auto;
    padding-bottom: 10px;
    display: block;
}

.footer-links-container {
    overflow: hidden;
    margin-left: 0px;
    margin-top: 2px;
    position: relative;
    z-index: 1;
    height: auto;
    text-align: center;
}

.footer-links {
    text-align: center;
    margin: 0px auto;
    width: 100%;
    color: white !important;
    font-size: 14px;
}

.footer-copywrite {
    text-align: center;
    margin-top: 5px;
    color: white;
}

.btmfooter {
    padding: 0 5px 0 5px;
}

.privacy-logo {
    max-height: 100px;
    margin: 0 auto;
    padding-bottom: 10px;
    display: block;
}

#ot-sdk-btn.ot-sdk-show-settings {
    color: #FFF !important;
}

#ot-sdk-btn.ot-sdk-show-settings:hover {
    text-decoration: underline;
}

.crown-logo {
    /* max-height: 45px; */
    max-height: 60px;
    z-index: 1000;
}

.crown-logo-container {
    background-color: #582C83;
    text-align: center;
}

.navbar-brand {
    padding: 2px 15px;
}

.navbar-center {
    width: 100%;
    display: flex;
    margin-top: 7px;
}

.navbar-center>li {
    flex: 1 1 auto;
    text-align: center;
}

.navbar {
    margin-bottom: 0px;
    border-radius: 1px;
    margin-left: auto;
    margin-right: auto;
    min-height: 10px;
    height: 90px;
    box-shadow: inset 0px 5px 7px -5px rgba(0, 0, 0, 0.4), 0 5px 7px -4px rgba(0, 0, 0, 0.4);
}

.navbar-collapse {
    padding-right: 0px;
    padding-left: 0px;
}

.global-nav {
    background-color: #582C83;
    border-bottom: 1px solid #444a54 !important;
    color: #8ea0ae;
}

.global-nav-a {
    color: #8ea0ae !important;
}

.global-li {
    padding: 10px;
    border-right: 1px solid #444a54 !important;
}

.nav>li>div>.global-nav-a:hover,
.nav>li>div>.global-nav-a:focus {
    text-decoration: none;
}

.nav li:hover {
    color: white;
    background-color: #582C83;
}

.navbar-nav>li>a {
    color: white !important;
    font-weight: bolder;
}

.navbar-fixed {
    top: 0;
    z-index: 100;
    position: fixed;
    width: 100%;
}

.nav a:hover {
    text-decoration: none;
    color: white;
}

.nav a:not(.no-top-margin) {
    margin-top: 14px;
    margin-left: 5px;
    margin-right: 5px;
}

.navbar-default .navbar-nav>.active>a:not(.no-top-margin) {
    border-bottom: 4px solid white;
    padding-bottom: 1px;
    background-image: none;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.active>a:not(.no-top-margin) {
    border-bottom: 4px solid white;
    padding-bottom: 1px;
    background-image: none;
}

.navbar-default .navbar-nav>li>.external-link:focus {
    border-bottom: none;
}

.navbar-default .navbar-nav>li>.external-link:hover {
    border-bottom: 4px solid white;
    padding-bottom: 1px;
    background-image: none;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    color: #555;
    background-color: #582C83;
    box-shadow: inset 0 3px 9px #582C83;
}

.sub-nav {
    /*background-color:#582C83; */
    background-color: #946fce;
    height: auto;
    position: fixed;
    width: 100%;
    z-index: 99;
}

.below-fixed-subnav {
    padding-top: 30px;
}

.sub-navbar-nav {}

.navbar-nav>li>.sub-nav-a {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.nav>li:hover>a:not(.no-top-margin),
.nav>li>a:focus:not(.no-top-margin) {
    border-bottom: 4px solid white;
    padding-bottom: 1px;
}

.nav>li>.sub-nav-a:hover,
.nav>li>.sub-nav-a:focus {
    background-color: #946fce;
}

.nav>.active>.sub-nav-a {
    background-color: #946fce;
}

.main-color {
    background-color: #582C83;
    border-color: #582C83;
    background-image: none;
}

.slide {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    left: 0;
    right: 0;
}

.carousel img {
    margin-left: auto;
    margin-right: auto;
}

.slide {
    margin-top: 0px;
}

.channel-logo-container {
    text-align: center;
}

.channel-logo {
    max-width: 150px;
    margin: 30px 15px 20px 15px;
    display: inline-block;
    transition: all .3s ease-in-out;
}

.channel-logo:hover {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.channel-logo-new {
    max-width: 145px;
    max-height: 50px;
    margin: 20px 10px;
    display: inline-block;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.channel-logo-new:hover {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.internship-logo {
    display: flex;

}

.hoverable {
    filter: opacity(.5);
}

.hoverable:hover {
    filter: opacity(1);
}

.slide-box {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    height: 200px;
    background-color: #582C83;
    margin-top: 0px;
    text-align: center;
}

.slide-text {
    height: 190px;
    background-color: white;
    border-radius: 0px 0px 60% 60%;
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
}

.slick-prev:before {
    color: grey;
}

.slick-next:before {
    color: grey;
}

.slick-prev:before {
    color: some-color;
}

.slick-next:before {
    color: some-color;
}

.slick-next,
.slick-prev {
    top: 60%;
}

.slick-slide {}

.carousel-indicators {
    display: none;
}

.carousel-caption {
    top: 8%;
    left: 55%;
    right: 10%;
    font-size: 14px;
    visibility: inherit;
    transition: none;
    text-align: inherit;
    line-height: 20px;
    border-width: 0px;
    margin: 0px 0px 0px 30px;
    padding: 0px;
    letter-spacing: 0px;
    font-weight: 300;
    white-space: normal;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: 350px;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}

.carousel-desc {
    margin: .8em 0;
    font-size: 16px;
    line-height: 1.5;
}

.carousel-button {
    background-color: #582C83;
    border: none;
    padding: 5px;
}

.carousel-button:hover {
    text-decoration: none;
}

.carousel-control.left {
    /*background-image: -webkit-linear-gradient(left, rgba(243, 239, 240, 1) 0%, rgba(0, 0, 0, .0001) 100%);
    background-image: -o-linear-gradient(left, rgba(243, 239, 240, 1) 0%, rgba(0, 0, 0, .0001) 100%);
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(243, 239, 240, .1)), to(rgba(0, 0, 0, .0001)));
    background-image: linear-gradient(to right, rgba(243, 239, 240, 1) 0%, rgba(0, 0, 0, .0001) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3eff0', endColorstr='#00000000', GradientType=1);*/
}

.carousel-control.right {
    /*background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .0001) 0%, rgba(243, 239, 240, 1) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, .0001) 0%, rgba(243, 239, 240, 1) 100%);
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .0001)), to(rgba(243, 239, 240, 1)));
    background-image: linear-gradient(to right, rgba(0, 0, 0, .0001) 0%, rgba(243, 239, 240, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#f3eff0', GradientType=1);*/
}

hr {
    border-top: 2px solid black;
    margin-top: 10px;
    margin-bottom: 15px;
}

.social-divider-hr {
    border-top: 1px solid #dfdfdf;
}

.title {
    display: inline-block;
}

.social {
    height: 10vh;
}

#twitter-feed {
    height: 500px !important;
    overflow-y: scroll;
}

.fa-circle {
    color: #4A317C;
    padding-right: 8px;
    font-size: 18px;
    margin-left: -7px;
}

.timeline-row {
    margin-bottom: 50px;
}

.timeline-header {
    margin-top: 10px;
}

.timeline-description {
    font-size: 16px;
    font-weight: bold;
    line-height: 100%;
}

.timeline-year {
    font-size: 20px;
    font-weight: bold;
}

.about-us-header {
    /*margin-top:25px;*/
}

.about-us-description {
    text-align: justify;
}

.network-page-container {
    margin-bottom: 20px;
}

.network-page-logos {
    max-height: 200px;
    max-width: 200px;
    display: inline-block;
    transition: all .3s ease-in-out;
}

.network-page-logos:hover {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.network-page-logo-container {
    text-align: center;
    height: 130px;
    position: relative;
}

.network-page-paragraphs {
    display: inline-block;
    margin-top: 10px;
}

.networks-hr {
    border-top: 5px solid #582C83;
    margin-top: 15px;
}

/* .executive-image {
    max-width: 210px;
    max-height: 270px;
    max-width: 20%;
    margin: 10px;
    position: relative;
    transition: all .3s ease-in-out;
} */

.executive-image:hover {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.executive-container {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    margin-top: 20px;
    padding: 50px 10px 10px 10px;
}

.executive-bio {
    text-align: center;
}

.exec-id1 {
    flex: 1 100% !important;
}


/* executive layout for 1-2-3 */

/* .col-md-3 {
    text-align: center;
    padding: 1rem;
}

.executive-title {
    width: 60%;
    align-content: center;
    margin: 0 auto;
}

.exec-id2, .exec-id3 {
    flex: 1 50%;
}

.exec-id2 {
    padding-left: 275px;
}

.exec-id3 {
     padding-right: 275px;
} */

/* executive layout for 1-2-3 */

/* executive layout for 1-3-3 */

/* .executive-bio {
    flex: 1 33.3%;
}

.executive-title {
    width: 60%;
    align-content: center;
    margin: 0 auto;
}
 */

/* executive layout for 1-3-3 */

/* executive layout for 1-3-5 */

/* .col-md-3 {
    width: 20%;
    padding-left: 0;
    padding-right: 0;
}

.exec-id2, .exec-id3, .exec-id4 {
    flex: 20.3%;
}

.exec-id2 {
    margin: 0px 0 0 225px;
}

.exec-id4 {
    margin: 0px 224px 0 0;

}  */

/* executive layout for 1-3-5 */

/* executive layout for 1-4-5 */

/* .col-md-3 {
    width: 20%;
    padding-left: 0;
    padding-right: 0;
}

.exec-id2, .exec-id3, .exec-id4, .exec-id5 {
    flex: 20.3%;
}

.exec-id2 {
    margin: 0px 0 0 108px;
}

.exec-id5 {
    margin: 0px 108px 0 0;

}  */

/* executive layout for 1-4-5 */

/* executive layout for 1-5-5 */

/* .col-md-3 {
    width: 20%;
    padding-left: 0;
    padding-right: 0;
} */
/* executive layout for 1-5-5 */

/* executive layout for 1-5-6 */

/* .col-md-3 {
    width: 16.7%;
    padding-left: 0;
    padding-right: 0;
} */

/* .exec-id7, .exec-id8, .exec-id9, .exec-id10, .exec-id11, .exec-id12 {
    width: 16.6%;
} */

.executive-image {
    max-width: 210px;
    max-height: 270px;
    margin: 10px 0 10px 0;

}

/* max-width: 20%; */
/* max-height: 50%; */



/* executive layout for 1-5-6 */


.executive-name-a,
.executive-name-a:visited,
.executive-name-a:active {
    color: black;
    text-decoration: underline;
    font-weight: bold;
}

.executive-name-a:hover {
    color: #946fce;
    text-decoration: underline;
}

.executive-download {
    color: black !important;
    text-decoration: underline;
    font-weight: bold;
    display: block;
    padding: 5px 20px 5px 20px;
}

.executive-download:hover {
    color: #946fce !important;
    text-decoration: underline;
}

.executive-name-span:hover {
    border-bottom: 4px solid #946fce;
}

.executive-detail-img {
    max-width: 100%;
    padding: 20px;
}

.executive-detail-bio {
    background-color: #F3EFF2 !important;
}

.executive-detail-container {
    padding-bottom: 20px;
}

/* Make Her Mark */

.below-make-her-mark-subnav {
    padding-top: 0;
}

.mhm-h4 {
    text-align: center;
    margin: 10px;
}

.mhm-formDeadline {
    text-align: center;
}



.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.contact-div {
    margin: 20px 0 0;
}

.contacts-container {
    min-height: 70vh;
    /*padding-top:20px;*/
}

.contacts-row {
    padding-bottom: 10px;
    margin-right: 15px;
    margin-left: 15px;
}

.contact-a {
    color: #333 !important;
    font-size: 18px;
    margin-top: 20px;
}

.contact-p {
    font-size: 18px;
    font-family: inherit;
    font-weight: 500;
    margin-bottom: 2px;
}

.press-container {
    padding-bottom: 50px;
}

.location0 {
    width: 240px;
}

.location3,
.location5 {
    width: 270px;
}


/* .locations-container {
    font-size: 18px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.5;
} */

.milestones-container {
    min-height: 50vh;
    margin: 25px;
}

.timeline-top-box {
    height: 90px;
    display: flex;
    align-items: center;
    padding: 10px;
}

.timeline-bottom-box {
    height: 90px;
    display: flex;
    align-items: center;
    padding: 10px;
}

.timeline-hr {}

.even-info {}

.even-year,
.odd-year {
    margin: 0 auto;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

button {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
}

body {}

.timeline {}

.fa-arrow-circle-right {
    color: #946fce;
    font-size: 30px;
    position: absolute;
    top: 44.5vh;
    right: 20px;
    cursor: pointer;
}

.fa-arrow-circle-left {
    color: #946fce;
    font-size: 30px;
    position: absolute;
    top: 44.5vh;
    left: 20px;
    cursor: pointer;
}

/* .section SECTION
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.section {
    padding: 50px 0;
}

.section .container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.section h1 {
    font-size: 2.5rem;
}

.section h2 {
    font-size: 1.3rem;
}


/* TIMELINE
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline {
    white-space: nowrap;
    overflow-x: hidden;
}

.timeline ol {
    font-size: 0;
    width: 100vw;
    padding: 250px 0;
    transition: all 1s;
}

.timeline ol li {
    position: relative;
    display: inline-block;
    list-style-type: none;
    width: 160px;
    height: 3px;
    background: lightgrey;
}

.timeline ol li:last-child {
    width: 280px;
}

.timeline ol li:not(:first-child) {
    margin-left: 14px;
}

.timeline ol li:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    left: calc(100% + 1px);
    bottom: 0;
    width: 12px;
    height: 12px;
    transform: translateY(-50%);
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
    border-radius: 50%;
    background: #946fce;
}

.timeline ol li>div {
    position: absolute;
    left: calc(100% + 7px);
    width: 280px;
    padding: 15px;
    font-size: 1rem;
    white-space: normal;
    color: black;
    background: #D8D9D6;
}

.timeline ol li>div::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
}

.timeline ol li:nth-child(odd) div {
    top: -16px;
    transform: translateY(-100%);
}

.timeline ol li:nth-child(odd)>div::before {
    top: 100%;
    border-width: 8px 8px 0 0;
    border-color: #D8D9D6 transparent transparent transparent;
    animation-name: fadeInOut, moveLeft300px, bounce;
    animation-duration: 3s;
    animation-iteration-count: 1;
}

.timeline ol li:nth-child(even) div {
    top: calc(100% + 16px);
}

.timeline ol li:nth-child(even)>div::before {
    top: -8px;
    border-width: 8px 0 0 8px;
    border-color: transparent transparent transparent #D8D9D6;
}

.timeline time {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 8px;
}


/* TIMELINE ARROWS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline .arrows {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.timeline .arrows .arrow__prev {
    margin-right: 20px;
}

.timeline .disabled {
    opacity: .5;
}

.timeline .arrows img {
    width: 45px;
    height: 45px;
}

/* PRIVACY LEGAL NOTICES  min-width: 1024px -------------------------------------------*/

.l-grid_layout-item .m-lg_12 {
    grid-column: span 12;
}


.l-grid_layout-item_in {
    margin-bottom: 20px;
}

/* GENERAL MEDIA QUERIES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (max-width: 599px) {

    .timeline ol,
    .timeline ol li {
        width: auto;
    }

    .timeline ol {
        padding: 0;
        transform: none !important;
    }

    .timeline ol li {
        display: block;
        height: auto;
        background: transparent;
    }

    .timeline ol li:first-child {
        margin-top: 25px;
    }

    .timeline ol li:not(:first-child) {
        margin-left: auto;
    }

    .timeline ol li div {
        width: 94%;
        height: auto !important;
        margin: 0 auto 25px;
    }

    .timeline ol li div {
        position: static;
    }

    .timeline ol li:nth-child(odd) div {
        transform: none;
    }

    .timeline ol li:nth-child(odd)>div::before,
    .timeline ol li:nth-child(even)>div::before {
        left: 50%;
        top: 100%;
        transform: translateX(-50%);
        border: none;
        border-left: 1px solid grey;
        height: 25px;
    }

    .timeline ol li:last-child,
    .timeline ol li:nth-last-child(2) div::before,
    .timeline ol li:not(:last-child)::after,
    .timeline .arrows {
        display: none;
    }
}

.arrows .disabled {
    opacity: .5;
}

.tl-container {
    position: relative;
    padding-right: 50px;
    padding-left: 50px;
}

#timelineContainer {
    position: relative;
}

.tl {
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    padding: 0px 20px;
}

.tl-main {
    margin: -20px 0px 20px 0px;
    min-height: 60vh;
}

.tl-small {
    margin: 0px 20px 0px 20px;
}

.tl-small-year-h3 {
    margin-top: 5px;
}

.tl-li {
    list-style-type: none;
    display: inline-block;
    flex: 0 0 auto;
}

.tl-main-div {
    /*background-color:#F8DA71;*/
    width: 375px;
    height: 300px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    font-size: small;
    transition: all .3s ease-in-out;
}

.tl-main-div:hover {
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.tl li:nth-child(even)>.tl-main-div {
    margin-top: 330px;
    margin-left: -120px;
}

.tl li:nth-child(odd):not(:first-child)>.tl-main-div {
    margin-left: -125px;
}

.tl li:nth-child(3n-5)>.tl-main-div {
    /*background-color:#A971AF;*/
}

.tl li:nth-child(3n+0)>.tl-main-div {
    /*background-color:#A0D9B3;*/
    height: 300px;
}

.tl li:nth-child(4n-2)>.tl-main-div {
    height: 300px;
}

.tl li:nth-child(6n+1)>.tl-main-div {
    /*margin-top:100px;*/
}

.tl li:nth-child(6n+5)>.tl-main-div {
    /*margin-top:100px;*/
}

.hc-milestone {
    background-color: #582C83;
}

.cm-milestone {
    background-color: #EFB827;
}

.hd-milestone {
    background-color: #946fce;
}

.hmm-milestone {
    background-color: #007481;
}

.hmn-milestone {
    background-color: #8A8A8D;
}

.hp-milestone {
    background-color: #8A8A8D;
}

.hce-milestone {
    background-color: #5BCAFE;
}

.tl-left-div {
    display: inline-block;
    width: 30%;
    height: 100%;
    vertical-align: top;
    background-color: #f5f5f5;
    position: relative;
}

.tl-right-div {
    position: relative;
    display: inline-block;
    width: 70%;
    height: 100%;
    vertical-align: top;
}

.tl-info-div {
    /*margin-left:8px;
	margin-right: 10px;*/
    color: white;
    font-weight: bolder;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
}

.tl-logo {
    max-width: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.tl-hr {
    border-top: 3px solid #dfdfdf;
    position: relative;
    top: 313px;
    margin-right: 0px;
    margin-left: 0px;
}

.tl-small-nav {
    top: 185px !important;
}

.tl-small-hr {
    border-top: 3px solid #dfdfdf;
    position: relative;
    top: 190px;
    margin-right: 25px;
    margin-left: 25px;
}

.tl-small-main-div {
    background-color: #F8DA71;
    width: 275px;
    height: 160px;
    padding: 10px;
    color: white;
}

.tl-year {
    font-size: 30px;
    margin-bottom: 0px;
}

.tl-date {
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 5px;
}

.tl-details {
    font-weight: 300;
}

.tl li:nth-child(odd):not(:first-child)>.tl-small-main-div {
    margin-left: -100px;
}

.tl li:nth-child(even)>.tl-small-main-div {
    margin-top: 195px;
    margin-left: -100px;
}

.tl li:nth-child(3n-5)>.tl-small-main-div {
    background-color: #A971AF;
}

.tl li:nth-child(3n+0)>.tl-small-main-div {
    background-color: #A0D9B3;
}

.tl li:nth-child(4n-2)>.tl-small-main-div {}

.tl li:nth-child(6n+1)>.tl-small-main-div {}

.tl li:nth-child(6n+5)>.tl-small-main-div {}

.progress-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    margin-top: 20px;
}

.progress-bar-segment {
    display: inline-block;
    border-top: 3px solid #dfdfdf;
    margin-top: 20px;
    flex: 1;
    cursor: pointer;
}

.progress-bar-segment::before {
    content: '';
    display: block;
    width: 10px;
    height: 9px;
    border-radius: 7.5px;
    background-color: #946fce;
    margin-top: -5px;
}

.progress-container .progress-bar-segment:not(:first-child):not(:last-child)>span {
    visibility: hidden;
}

.progress-container .progress-bar-segment:not(:first-child):not(:last-child):hover>span {
    visibility: inherit;
}

.progress-container .progress-bar-segment:last-child {
    flex: 0;
}

.progress-year:hover {
    cursor: pointer;
}

.tl-vertical {
    /* margin: 0px 0px 20px 0px; */
    min-height: 100vh;
    /* background-image: url("/images/Crown_Black.png");
    background-position: center;  
    background-repeat: no-repeat; */
}

.tl-vertical-logo-div {
    text-align: center;
    background-color: white;
    padding: 5px;
    height: 75px;
}

.tl-vertical-logo {
    max-height: 55px;
    max-width: 150px;
    margin-top: 5px;
}

.tl-vertical-info-div {
    color: white;
    padding: 5px;
}

.tl-vertical-main-div {
    /*background-color:#F8DA71;*/
    width: 330px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    font-size: small;
    transition: all .3s ease-in-out;
    min-height: 150px;
    margin-right: 15px;
    margin-left: 15px;
}

.tl-vertical-main-div:hover {
    z-index: 100000;
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.tl li:nth-child(even)>.tl-vertical-main-div {
    position: absolute;
    margin-top: 355px;
    margin-left: -160px;
}

.tl-cm-logo {
    margin-top: 15px;
}

.tl-tve-logo {
    margin-top: 10px;
}

.tl-podcast-logo {
    margin-top: 20px;
}


@media(max-width: 880px) {

    .tl-container {
        padding-left: 0px;
        padding-right: 0px;
    }

    .progress-container {
        display: none;
    }

    .tl-container {
        position: relative;
    }

    .tl {
        /* margin: 20px;
        padding-bottom: 20px; */
        display: block;
        position: relative;

    }

    .tl-li {
        list-style-type: none;
        display: block;
        flex: 0 0 auto;
    }

    .tl-main-div {
        width: auto;
        height: auto !important;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        margin-top: 10px !important;
        margin-left: 0px !important;
        text-align: center;
    }

    .tl-left-div {
        display: none;
        width: 30%;
        height: 100%;
        vertical-align: top;
        background-color: #f5f5f5;
    }

    .tl-right-div {
        position: inherit;
        display: inline-block;
        width: 100%;
        height: 100%;
        vertical-align: top;
    }

    .tl-info-div {
        margin-left: 8px;
        margin-right: 10px;
        color: white;
        font-weight: bolder;
        transform: none;
        position: inherit;
    }

    .tl-logo {
        max-width: 100px;
        margin-top: 55%;
        margin-left: 10px;
    }

    .tl-hr {
        display: none;
    }

    .fa-arrow-circle-right {
        display: none;
    }

    .fa-arrow-circle-left {
        display: none;
    }

    .tl-vertical {
        margin: 0px 0px 20px 0px;
        min-height: 70vh;
    }

    .tl-vertical-logo-div {
        text-align: center;
        background-color: white;
        padding: 5px;
        min-height: 55px;
    }

    .tl-vertical-logo {
        max-height: 45px;
        max-width: 150px;
    }

    .tl-vertical-info-div {
        color: white;
        padding: 5px;
    }

    .tl-vertical-main-div {
        /*background-color:#F8DA71;*/
        width: auto;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        font-size: small;
        transition: all .3s ease-in-out;
        margin-top: 10px;
    }

    .tl li:nth-child(even)>.tl-vertical-main-div {
        position: relative;
        margin-top: 10px !important;
        margin-left: 10px;
    }

    .tl-cm-logo {
        margin-top: 5px;
    }
}

@media (max-width: 1000px) {
    .social-feed-container {
        display: none;
    }

    .channel-logo {
        margin: 5px 5px 5px 5px;
        transform: scale(.75);
    }

    .network-page-logos {
        max-width: 175px;
    }
}

@media (max-width: 1199px) {
    .navbar {
        height: auto;
    }

    .content {
        margin-top: 50px;
    }

    .global-li {
        padding: 2px;
        font-size: small;
        float: left !important;
    }

    .nav a:not(.no-top-margin) {
        margin-top: 0px;
    }

    .sub-nav-li {
        padding: 2px;
        font-size: small;
        float: left !important;
    }

    .sub-navbar-nav {
        margin: 0 !important;
    }

    .global-container {
        width: 100% !important;
    }

    .global-navbar-collapse {
        padding-right: 0px;
        padding-left: 0px;
    }

    .navbar-header {
        float: none;
    }

    .navbar-toggle {
        display: block;
    }

    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-nav {
        float: none !important;
        /*margin: 7.5px -15px;*/
        margin: 7.5px 50px 7.5px -15px;
    }

    .navbar-nav>li {
        float: none;
    }

    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 30px;
    }

    .navbar-text {
        float: none;
        margin: 15px 0;
    }

    /* since 3.1.0 */
    .navbar-collapse.collapse.in {
        display: block !important;
    }

    .collapsing {
        overflow: hidden !important;
    }

    .navbar-center {
        width: inherit;
        display: inherit;
    }

    .navbar-center>li {
        flex: none;
    }
}

@media (max-width: 872px) {
    .global-nav {
        display: none;
    }
}

@media(max-width: 680px) {
    .navbar-nav>li>.sub-nav-a {
        padding-right: 3px;
        padding-left: 3px;
        font-size: smaller;
    }

    .carousel-caption {
        top: 0%;
        margin: 0px;
    }
}

.brand-header-container {
    background: #edecea;
    border-bottom: solid 1px #a3a5a6;
    position: relative;
    z-index: 10;
}

@media only screen and (max-width: 1024px) {
    .brand-header-container[data-responsive-tablet-l="off"] {
        display: none;
    }

    .brand-header-container[data-responsive-tablet-l="on"] {
        display: block;
    }
}

@media only screen and (max-width: 768px) {
    .brand-header-container[data-responsive-tablet-p="off"] {
        display: none;
    }

    .brand-header-container[data-responsive-tablet-p="on"] {
        display: block;
    }
}

@media only screen and (max-width: 700px) {
    .brand-header-container[data-responsive-mobile="off"] {
        display: none;
    }

    .brand-header-container[data-responsive-mobile="on"] {
        display: block;
    }
}

.brand-header-container * {
    box-sizing: border-box;
}

.brand-header-container ul,
.brand-header-container li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.brand-header-container .brand-header-wrapper {
    margin: 0 auto;
    /*max-width: 840px;*/
    max-width: 715px;
}

@media only screen and (max-width: 768px) {
    .brand-header-container .brand-header-wrapper {
        padding-right: 120px;
        max-width: 100%;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .brand-header-container .brand-header-wrapper:before {
        content: '\203A';
        color: #a3a5a6;
        font-size: 32px;
        text-align: center;
        line-height: 36px;
        width: 40px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 9;
    }

    .brand-header-container .brand-header-wrapper:after {
        content: '';
        background: #edecea;
        background: linear-gradient(to right, rgba(237, 236, 234, 0) 0%, rgba(237, 236, 234, 0.75) 25%, #edecea 75%, #edecea 100%);
        height: 40px;
        width: 96px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 8;
    }
}

.brand-header-container .brand-header {
    align-items: center;
    display: flex;
    height: 40px;
}

.brand-header-container .brand {
    flex: 1 0 auto;
    height: 100%;
}

.brand-header-container .brand:first-child {
    border-left: solid 1px #a3a5a6;
}

.brand-header-container .brand {
    border-right: solid 1px #a3a5a6;
}

.brand-header-container .brand:hover,
.brand-header-container .brand[data-selected] {
    background: white;
}

.brand-header-container .brand:hover svg,
.brand-header-container .brand[data-selected] svg {
    fill: #293035;
}

[data-brand] {
    max-width: 120px;
    position: relative;
    transition: all .35s ease-in-out 0s;
}

@media only screen and (max-width: 768px) {
    [data-brand] {
        min-width: 120px;
    }
}

[data-brand] a {
    display: block;
    height: 100%;
}

[data-brand] svg {
    fill: #5d6166;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .35s ease-in-out 0s;
}

[data-brand="hallmark"] svg {
    width: 81px;
}

[data-brand="hallmark-baby"] svg {
    width: 48px;
    top: calc(50% - 4px);
}

[data-brand="hallmark-ecards"] svg {
    width: 64px;
    top: calc(50% - 2px);
}

[data-brand="hallmark-ink-main"] svg {
    width: 68px;
}

[data-brand="hallmark-business-connections"] svg {
    width: 92px;
}

[data-brand="hallmark-channel"] svg {
    width: 72px;
}

[data-brand="hallmark-movies-mysteries"] svg {
    width: 80px;
}

[data-brand="hallmark-movies-now"] svg {
    width: 64px;
}

/*.careers-carousel
{
    width:auto;
    margin-left:20px;
    margin-right:20px;
}*/

.careers-frame {
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 70vh;
    width: 100%;
}

.careers-sub-header {
    font-size: 20px;
}

.careers-p {
    margin: .8em 0;
    font-size: 16px;
    line-height: 1.4;
    text-align: justify;
}

.careers-a {
    color: black !important;
    text-decoration: underline;
}

.careers-img {
    width: 100%;
}

.careers-linkedin-col {
    padding-top: 20px;
    text-align: right;
}

.partner-image-div {
    position: relative;
}

.partner-image {
    max-height: 100px;
    max-width: 100px;
    display: inline-block;
    margin: 10px 10px 0px 0px;
}

.partner-title-a {
    color: black !important;
}

.diversity-hr {
    border-top: 2px solid #dfdfdf;
}

.pageBannerImg {
    max-width: 100%;
}

.diversity-img-background {}

.diversity-outer-container {
    /*background-color: #f3eff0;*/
}

.diversity-full-page {
    padding-bottom: 30px;
}

/*Diversity Employee Resource Group & Hallmark Media Celebrates*/

.diversity-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.items {
    padding: 10px;
    text-align: center;
}

.resource-container {
    padding: 0.8em 0;
    margin: 0 2em;
}

/* .resource-items {
    border: 3px black solid;

} */

.resource-items:nth-child(2) {
    border-left: none;
}

.group-logo {
    width: 100%;
}

.group-img {
    max-height: 150px;
    display: block;
    margin: 0 auto;
}

.group-desc {
    text-align: center;
    padding: 10px 0 0;
    /* width: 300px; */
}

.celebrates-container {
    flex-wrap: wrap;
    margin: 10px;
    padding: 0;
    /* border-left:3px solid black;
    border-top:3px solid black; */
    overflow: hidden;

}

.celebrates-items {
    /* border: solid 3px;
    margin-right: -1px;
    margin-bottom: -3px; */
    width: 33.3333%;
    /* border-bottom:3px solid black;
    border-right:3px solid black; */
    float: left;
}

.subtext {
    padding: 0.8em 0;
}

.celebrates-img {
    max-width: 100%;
}



.connect-with-us-h {
    display: inline;
    padding-right: 10px;
}

.soc-icon {
    padding: 10px;
    color: #582C83;
    display: inline-block;
}

.soc-icon:hover {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.sub-nav-a {
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    transition: color 0.3s;
}

.sub-nav-a::before,
.sub-nav-a::after {
    position: absolute;
    top: 52%;
    left: 50%;
    color: transparent;
    content: '•';
    text-shadow: 0 0 transparent;
    font-size: 1em;
    -webkit-transition: text-shadow 0.3s, color 0.3s;
    -moz-transition: text-shadow 0.3s, color 0.3s;
    transition: text-shadow 0.3s, color 0.3s;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
    pointer-events: none;
}

.active .sub-nav-a:before,
.sub-nav-a:focus::before {
    color: #fff;
    text-shadow: 10px 0 #fff, -10px 0 #fff;
}

.external-link .sub-nav-a:focus::before {
    color: transparent;
    text-shadow: 10px 0 transparent, -10px 0 transparent;
}

.sub-nav-a:hover::before {
    color: #fff !important;
    text-shadow: 10px 0 #fff, -10px 0 #fff !important;
}

.sub-nav-a:hover,
.sub-nav-a:focus {
    color: #d7a932;
}

.adoption-p {
    margin: .8em 0;
    font-size: 16px;
    line-height: 1.7;
    text-align: justify;
}

.adoption-link-out-div {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.adoption-link-out {
    background-color: #582C83;
    padding: 8px;
}

.adoption-link-out:hover,
.adoption-link-out:active,
.adoption-link-out:focus {
    cursor: pointer;
    text-decoration: none;
}

.timeline-columns-container {
    display: flex;
}

.timeline-event-li {}

.timeline-info {
    padding-left: 10px;
    padding-right: 10px;
}

.timeline-event-div {
    margin: 20px 15px 20px 15px;
    height: 260px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    transition: all .3s ease-in-out;
}

.timeline-event-div:hover {
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.timeline-event-li:nth-child(2n)>.timeline-event-div {
    max-width: 375px;
}

.timeline-event-li:nth-child(4n)>.timeline-event-div {
    max-width: 450px;
}

.timeline-event-li:nth-child:nth-child(3n+4)>.timeline-event-div {
    max-width: 500px;
}

.timeline-event-div-left {
    margin-left: auto;
}

.timeline-event-div-right {}

.tl-info-w-image {
    /*margin-left:8px;
  margin-right: 10px;*/
    color: white;
    font-weight: bolder;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    z-index: 100;
    text-align: left;
}

.timeline-event-img {
    max-width: 100%;
    margin: 10px;
    transition: all .3s ease-in-out;
    -moz-box-shadow: 0 0 5px #888;
    -webkit-box-shadow: 0 0 5px #888;
    box-shadow: 0 0 5px #888;
}

.timeline-event-img:hover {
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.movie-poster {
    height: 79vh;
}

.short-img {
    height: 38vh;
}

.tl-horizontal-full-default-div {
    width: 348px;
    height: 38vh;
    margin: 10px;
    transition: all .3s ease-in-out;
    -moz-box-shadow: 0 0 5px #888;
    -webkit-box-shadow: 0 0 5px #888;
    box-shadow: 0 0 5px #888;
}

.tl-horizontal-full-default-logo-div {
    text-align: center;
    background-color: white;
    padding: 5px;
    min-height: 85px;
}

.tl-full-horizontal-default-logo {
    text-align: center;
    background-color: white;
    margin-top: 10px;
    padding: 5px;
    max-height: 65px;
}

.tl-horizontal-full-default-div:hover {
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.horizontal-full-images-default-info {
    margin: 10px 5px 10px 5px;
}

.horizontal-full-images-default-details {
    font-weight: bold;
}

.horizontal-full-images-default-year {
    font-weight: bold;
    font-size: 40px;
    line-height: 1;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.horizontal-full-images-default-date {
    font-weight: bold;
    font-size: 28px;
    line-height: 1;
    margin-top: 0px;
    padding-top: 0px;
}

.left-events {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    text-align: right;
    padding-top: 45px;
}

.right-events {
    display: inline-flex;
    flex-direction: column;
    width: 50%;
    text-align: left;
    padding-top: 145px;
}

.spaced-between {
    justify-content: space-between;
}

.floated-logo-div {
    float: right;
}

.timeline-logo {
    max-width: 90%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.horizontal-full-images-container {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 85vh;
    width: 100%;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    padding: 0px 20px 20px 20px;
}

.horizontal-full-images-default-width {
    width: 540px;
}

.side-progress {
    /*height: 80%; */
    position: fixed;
    /* Fixed Sidebar (stay in place on scroll) */
    width: 60px;
    z-index: 1;
    /* Stay on top */
    /*top: 125px; /* Stay at the top */
    right: 25px;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0;
}

.side-progress-state-1 {
    height: 80%;
    top: 125px;
    /* Stay at the top */
}

.side-progress-state-2 {
    height: 65%;
    top: 100px;
    /* Stay at the top */
}

.progress-li {
    cursor: pointer;
    transition: all .3s ease-in-out;
    border-right: 3px solid #dfdfdf;
    height: 100%;
}

.side-progress .progress-li:not(:first-child):not(:last-child)>span {
    visibility: hidden;
}

.side-progress .progress-li:not(:first-child):not(:last-child):hover>span {
    visibility: inherit;
}

.side-progress .progress-li:last-child {}

.progress-li::after {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: #946fce;
    position: absolute;
    left: 56px;
    margin-top: 5px;
}

.timeline-circle {
    display: inline-block;
}

/* legal documentations styling */


/* .l-grid_layout {
    margin: 0 auto;
    max-width: 1920px;
    padding: 88px 0px 88px 20px;
    background-color: var(--bg-layout-color);
    background-position: var(--bg-image-position);
    background-repeat: var(--bg-image-repeat);
    background-size: var(--bg-image-size);

} */

/* .b-text_block h1,
.b-text_block .title-h1 {
    font-family: "Queens Hat", Times, serif;
    font-size: 60px;
    font-weight: 350;
    line-height: 68px;
}

.b-text_block p {
    font-weight: 102;
    margin-bottom: 4px;
} */




@media (max-width: 1199px) {
    .timeline-event-div {
        height: 355px;
    }
}

@media(max-width: 880px) {
    .side-progress {
        display: none;
    }

    .timeline-event-div {
        height: auto;
    }

    .about-us-featured-logo {
        padding: 2px;
    }
}

@media(max-width: 855px) {
    .left-events {
        width: 100%;
    }

    .right-events {
        width: 100%;
    }

    .timeline-event-div {
        width: 100%;
        max-width: 100%;
        text-align: left;
        margin: 15px;
    }
}

@media(max-width: 880px) {
    .horizontal-full-images-container {
        list-style: none;
        padding: 0;
        margin: 0;
        display: block;
        height: auto;
        width: 100%;
        padding: 0 20px 20px;
        text-align: center;
    }

    .timeline-event-img {
        max-width: 100%;
        height: auto;
    }

    .tl-horizontal-full-default-div {
        width: auto;
        height: auto;
        margin: 10px;
    }

    .tl-vertical-logo-div {
        height: auto;
    }
}

.hr-no-margin {
    margin-bottom: 0px;
}

.img-quarter {
    width: 25%;
    display: inline-block;
    padding: 5px;
}

.underline-animate {
    text-decoration: underline;
}

.underline-animate:hover {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.025);
}

.internships-list {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(min-width: 681px) and (max-width:991px) {
    .below-about-subnav {
        padding-top: 70px !important;
    }
}

@media (max-width:582px) {
    .below-about-subnav {
        padding-top: 70px !important;
    }
}

@media (max-width:394px) {
    .below-fixed-subnav {
        padding-top: 70px !important;
    }

    .below-make-her-mark-subnav {
        padding-top: 0 !important;
    }
}

@media(min-width:1200px) {
    .social-container {
        display: flex;
        justify-content: center;
    }

    .social-links {
        margin: 12px 0;
    }


    .l-static_page-item {
        margin: 0 auto;
        max-width: 1920px;
        color: #293035;
        /* margin-bottom: 72px; */
    }


}

.vertical-center-tl {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
}

.mhm-slideshow {
    padding: 2rem 15px;
}

.make-her-mark-text-container {
    /* padding: 0 15px 2rem; */
    padding: 2rem 15px;
    scroll-margin: 120px;
}

.make-her-mark-p {
    font-size: 16px;
    line-height: 1.7;
    text-align: justify;
}

.mhm-title.mhm-h4,
.about-founder-title.mhm-h4 {
    font-size: 20px;
}

.founder-image-div {
    width: 25%;
}

.about-founder-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mhm-title.mhm-h4 {
    margin-bottom: 20px;
}

.mhm-bio-link {
    text-decoration: underline;
    color: blue !important;
}

.mhm-bio-container p {
    text-align: justify;
}

.mhm-bio-container span {
    font-size: 16px;
    font-family: tahoma, geneva, sans-serif;
}

.formstack-parent {
    margin-bottom: 45px;
}

.formstack-iframe {
    height: 100%;
    width: 100%;
    border: none;
    background-color: #333;
}

.makehermark-arrow {
    display: none;
    color: #582C83;
    position: fixed;
    bottom: 5px;
    right: 0;
    cursor: pointer;
    border-radius: 50px;
    z-index: 100;
    /* opacity: 0; */
    /* transform: translateY(100px);*/
    transition: all .5s ease;
}

.makehermark-arrow:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    width: 75px;
    border-radius: 50%;
    border: 1px solid #582C83;

}

.showBtn {
    opacity: 1;
    transform: translateY(0)
}

.arrow-show {
    display: block;
}

.arrow-bottom {
    bottom: 202px;
}

.formstack-container {
    display: none;
}

.form-show {
    display: block;
}