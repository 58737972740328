html {
    background-color: #fff;

}

.priv-container {
    background-color: #fff;
    margin-bottom: 80px;
}

.l-grid_layout {
    margin: 0 auto;
    max-width: 1920px;
    padding-left: 88px;
    padding-right: 88px;
}

a.a-links {
    color: #7030A0;
    text-decoration: underline;
}

.l-static_page {
    color: #293035;
}

.b-user_content {
    font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans Bold', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    font-stretch: 700%;
    background-color: #fff;


}

.l-static_page-item {

}

.h4 {
    font-weight: bold;
}

@media screen and (min-width: 1024px)and (max-width: 1366.9px) {
    .l-grid_layout {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout {
        padding-left: 32px;
        padding-right: 32px;
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-padding_top-sm {
        padding-top: 10px;
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-padding_top-sm {
        padding-top: 10px;
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout.m-padding_top-sm {
        padding-top: 10px;
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-padding_top-md {
        padding-top: 20px;
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-padding_top-md {
        padding-top: 20px;
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout.m-padding_top-md {
        padding-top: 20px;
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-padding_top-lg {
        padding-top: 40px;
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-padding_top-lg {
        padding-top: 40px;
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout.m-padding_top-lg {
        padding-top: 40px;
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-padding_top-xl {
        padding-top: 20px;
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-padding_top-xl {
        padding-top: 20px;
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout.m-padding_top-xl {
        padding-top: 80px;
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-padding_bottom-sm {
        padding-bottom: 10px;
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-padding_bottom-sm {
        padding-bottom: 10px
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout.m-padding_bottom-sm {
        padding-bottom: 10px
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-padding_bottom-md {
        padding-bottom: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-padding_bottom-md {
        padding-bottom: 20px
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout.m-padding_bottom-md {
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-padding_bottom-lg {
        padding-bottom: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-padding_bottom-lg {
        padding-bottom: 40px
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout.m-padding_bottom-lg {
        padding-bottom: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-padding_bottom-xl {
        padding-bottom: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-padding_bottom-xl {
        padding-bottom: 20px
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout.m-padding_bottom-xl {
        padding-bottom: 80px
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-margin_top-sm {
        margin-top: 10px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-margin_top-sm {
        margin-top: 10px
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout.m-margin_top-sm {
        margin-top: 10px
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-margin_top-md {
        margin-top: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-margin_top-md {
        margin-top: 20px
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout.m-margin_top-md {
        margin-top: 20px
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-margin_top-lg {
        margin-top: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-margin_top-lg {
        margin-top: 40px
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout.m-margin_top-lg {
        margin-top: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-margin_top-xl {
        margin-top: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-margin_top-xl {
        margin-top: 20px
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout.m-margin_top-xl {
        margin-top: 80px
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-margin_bottom-sm {
        margin-bottom: 10px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-margin_bottom-sm {
        margin-bottom: 10px
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout.m-margin_bottom-sm {
        margin-bottom: 10px
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-margin_bottom-md {
        margin-bottom: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-margin_bottom-md {
        margin-bottom: 20px
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout.m-margin_bottom-md {
        margin-bottom: 20px
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-margin_bottom-lg {
        margin-bottom: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-margin_bottom-lg {
        margin-bottom: 40px
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout.m-margin_bottom-lg {
        margin-bottom: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-margin_bottom-xl {
        margin-bottom: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-margin_bottom-xl {
        margin-bottom: 20px
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout.m-margin_bottom-xl {
        margin-bottom: 80px
    }
}

/* @media screen and (max-width: 767.9px) {
    .l-grid_layout {
        background-image:var(--bg-image-mobile)
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout {
        background-image:var(--bg-image-tablet)
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout {
        background-image:var(--bg-image-desktop)
    }
} */

.l-grid_layout.m-full_bleed,
.l-grid_layout.m-full_with_centered {
    max-width: 1920px;
    padding-left: 0;
    padding-right: 0
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {

    .l-grid_layout.m-tablet_wrap.m-4_8 .l-grid_layout-item,
    .l-grid_layout.m-tablet_wrap.m-8_4 .l-grid_layout-item,
    .l-grid_layout.m-tablet_wrap.m-2_up .l-grid_layout-item,
    .l-grid_layout.m-tablet_wrap.m-3_up .l-grid_layout-item,
    .l-grid_layout.m-tablet_wrap.m-3_9 .l-grid_layout-item,
    .l-grid_layout.m-tablet_wrap.m-9_3 .l-grid_layout-item {
        grid-column: span 12
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {

    .l-grid_layout.m-tablet_wrap.m-6_3_3 .l-grid_layout-item.m-md_6,
    .l-grid_layout.m-tablet_wrap.m-3_3_6 .l-grid_layout-item.m-md_6 {
        grid-column: span 12
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {

    .l-grid_layout.m-tablet_wrap.m-6_3_3 .l-grid_layout-item.m-md_3,
    .l-grid_layout.m-tablet_wrap.m-3_3_6 .l-grid_layout-item.m-md_3 {
        grid-column: span 6
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-tablet_wrap.m-4_pack_wide .l-grid_layout-item.m-md_3 {
        grid-column: span 6
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-tablet_wrap.m-6_pack_tall .l-grid_layout-item {
        grid-column: span 4
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-tablet_wrap.m-3_9 .l-grid_layout-item {
        grid-column: span 4
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-2_8_2 .l-grid_layout-item.m-lg_2 {
        display: none
    }
}

.l-grid_layout-content {
    display: grid
}

@media screen and (min-width: 1367px) {
    .l-grid_layout-content {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end]
    }
}

@media screen and (min-width: 1024px)and (max-width: 1366.9px) {
    .l-grid_layout-content {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end]
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout-content {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end]
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout-content {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end]
    }
}

.m-full_with_centered .l-grid_layout-content {
    margin: 0 auto;
    max-width: 1920px;
    padding-left: 88px;
    padding-right: 88px
}

@media screen and (min-width: 1024px)and (max-width: 1366.9px) {
    .m-full_with_centered .l-grid_layout-content {
        padding-left: 20px;
        padding-right: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .m-full_with_centered .l-grid_layout-content {
        padding-left: 32px;
        padding-right: 32px
    }
}

@media screen and (max-width: 767.9px) {
    .m-full_with_centered .l-grid_layout-content {
        padding-left: 15px;
        padding-right: 15px
    }
}

.l-grid_layout-content.m-hide_gutters {
    grid-gap: 0
}

.l-grid_layout-item {
    max-width: 100%
}

.l-grid_layout-item.m-horizontal_left,
.l-grid_layout-item.m-horizontal_center,
.l-grid_layout-item.m-horizontal_right {
    display: flex;
    flex-wrap: wrap;
    width: 100%
}

.l-grid_layout-item.m-horizontal_left {
    justify-content: flex-start
}

.l-grid_layout-item.m-horizontal_center {
    justify-content: center
}

.l-grid_layout-item.m-horizontal_right {
    justify-content: flex-end
}

.l-grid_layout-item.m-vertical_top {
    align-self: flex-start
}

.l-grid_layout-item.m-vertical_middle {
    align-self: center
}

.l-grid_layout-item.m-vertical_bottom {
    align-self: flex-end
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout-item.m-margin_bottom-sm .l-grid_layout-item_in {
        margin-bottom: 10px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout-item.m-margin_bottom-sm .l-grid_layout-item_in {
        margin-bottom: 10px
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout-item.m-margin_bottom-sm .l-grid_layout-item_in {
        margin-bottom: 10px
    }
}

.l-grid_layout-item.m-margin_bottom-sm .l-grid_layout-item_in:last-child {
    margin-bottom: 0
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout-item.m-margin_bottom-md .l-grid_layout-item_in {
        margin-bottom: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout-item.m-margin_bottom-md .l-grid_layout-item_in {
        margin-bottom: 20px
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout-item.m-margin_bottom-md .l-grid_layout-item_in {
        margin-bottom: 20px
    }
}

.l-grid_layout-item.m-margin_bottom-md .l-grid_layout-item_in:last-child {
    margin-bottom: 0
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout-item.m-margin_bottom-lg .l-grid_layout-item_in {
        margin-bottom: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout-item.m-margin_bottom-lg .l-grid_layout-item_in {
        margin-bottom: 40px
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout-item.m-margin_bottom-lg .l-grid_layout-item_in {
        margin-bottom: 40px
    }
}

.l-grid_layout-item.m-margin_bottom-lg .l-grid_layout-item_in:last-child {
    margin-bottom: 0
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout-item.m-margin_bottom-xl .l-grid_layout-item_in {
        margin-bottom: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout-item.m-margin_bottom-xl .l-grid_layout-item_in {
        margin-bottom: 20px
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout-item.m-margin_bottom-xl .l-grid_layout-item_in {
        margin-bottom: 80px
    }
}

.l-grid_layout-item.m-margin_bottom-xl .l-grid_layout-item_in:last-child {
    margin-bottom: 0
}

.l-grid_layout-item_in {
    display: flex
}

.l-grid_layout-item_in:only-child {
    height: 100%
}

@media screen and (max-width: 1023.9px) {
    .l-grid_layout.m-8_4 .l-grid_layout-item_in {
        margin-bottom: 0
    }
}

.l-grid_layout-item_in>div,
.l-grid_layout-item_in>section {
    width: 100%
}

.l-grid_layout-item[data-items=one] {
    align-self: auto
}

.l-grid_layout-item.m-title {
    grid-column: span 6;
    order: -2
}

@media screen and (min-width: 768px) {
    .l-grid_layout-item.m-title {
        grid-column: span 12
    }
}

.l-grid_layout-item.m-fade_in>div,
.l-grid_layout-item.m-fade_in>section {
    opacity: 0;
    transform: translateY(10%);
    transition: 1.2s ease-out;
    transition-property: transform, opacity
}

.l-grid_layout-item.m-bounce {
    overflow: hidden
}

.l-grid_layout-item.m-bounce>div,
.l-grid_layout-item.m-bounce>section {
    opacity: 0;
    transform: scale(0.8)
}

.l-grid_layout-item.m-fade_in.m-animated>div,
.l-grid_layout-item.m-fade_in.m-animated>section {
    opacity: 1;
    transform: translateY(0)
}

.l-grid_layout-item.m-bounce.m-animated>div,
.l-grid_layout-item.m-bounce.m-animated>section {
    animation-duration: 1.2s;
    animation-fill-mode: both;
    animation-name: growIn
}

@media screen and (max-width: 1023.9px) {
    .l-grid_layout.m-8_4 .l-grid_layout-item:not([data-items=one]):last-child {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr
    }
}

.l-grid_layout-item.m-sm_6 {
    grid-column: span 6
}

.l-grid_layout-item.m-sm_5 {
    grid-column: span 5
}

.l-grid_layout-item.m-sm_4 {
    grid-column: span 4
}

.l-grid_layout-item.m-sm_3 {
    grid-column: span 3
}

.l-grid_layout-item.m-sm_2 {
    grid-column: span 2
}

.l-grid_layout-item.m-sm_1 {
    grid-column: span 1
}

@media screen and (min-width: 768px) {
    .l-grid_layout-item.m-md_12 {
        grid-column: span 12
    }
}

@media screen and (min-width: 768px) {
    .l-grid_layout-item.m-md_11 {
        grid-column: span 11
    }
}

@media screen and (min-width: 768px) {
    .l-grid_layout-item.m-md_10 {
        grid-column: span 10
    }
}

@media screen and (min-width: 768px) {
    .l-grid_layout-item.m-md_9 {
        grid-column: span 9
    }
}

@media screen and (min-width: 768px) {
    .l-grid_layout-item.m-md_8 {
        grid-column: span 8
    }
}

@media screen and (min-width: 768px) {
    .l-grid_layout-item.m-md_7 {
        grid-column: span 7
    }
}

@media screen and (min-width: 768px) {
    .l-grid_layout-item.m-md_6 {
        grid-column: span 6
    }
}

@media screen and (min-width: 768px) {
    .l-grid_layout-item.m-md_5 {
        grid-column: span 5
    }
}

@media screen and (min-width: 768px) {
    .l-grid_layout-item.m-md_4 {
        grid-column: span 4
    }
}

@media screen and (min-width: 768px) {
    .l-grid_layout-item.m-md_3 {
        grid-column: span 3
    }
}

@media screen and (min-width: 768px) {
    .l-grid_layout-item.m-md_2 {
        grid-column: span 2
    }
}

@media screen and (min-width: 768px) {
    .l-grid_layout-item.m-md_1 {
        grid-column: span 1
    }
}



@media screen and (min-width: 1024px) {
    .l-grid_layout-item.m-lg_11 {
        grid-column: span 11
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout-item.m-lg_10 {
        grid-column: span 10
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout-item.m-lg_9 {
        grid-column: span 9
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout-item.m-lg_8 {
        grid-column: span 8
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout-item.m-lg_7 {
        grid-column: span 7
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout-item.m-lg_6 {
        grid-column: span 6
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout-item.m-lg_5 {
        grid-column: span 5
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout-item.m-lg_4 {
        grid-column: span 4
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout-item.m-lg_3 {
        grid-column: span 3
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout-item.m-lg_2 {
        grid-column: span 2
    }
}

@media screen and (min-width: 1024px) {
    .l-grid_layout-item.m-lg_1 {
        grid-column: span 1
    }
}

.l-grid_layout-item_title {
    padding-bottom: 20px
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout-item_title {
        padding-bottom: 16px
    }
}

.l-grid_layout.m-full_bleed .l-grid_layout-item_title {
    margin: 0 auto;
    max-width: 1920px;
    padding-left: 88px;
    padding-right: 88px
}

@media screen and (min-width: 1024px)and (max-width: 1366.9px) {
    .l-grid_layout.m-full_bleed .l-grid_layout-item_title {
        padding-left: 20px;
        padding-right: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout.m-full_bleed .l-grid_layout-item_title {
        padding-left: 32px;
        padding-right: 32px
    }
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-full_bleed .l-grid_layout-item_title {
        padding-left: 15px;
        padding-right: 15px
    }
}

.l-static_page:not(.m-full_width) .l-grid_layout.m-full_bleed .l-grid_layout-item_title {
    padding-left: 0;
    padding-right: 0
}

.l-grid_layout.m-custom .l-grid_layout-item {
    display: flex
}

.l-grid_layout.m-custom .l-grid_layout-item>div {
    width: 100%
}

@media screen and (max-width: 767.9px) {
    .l-grid_layout.m-mobile_reversed .l-grid_layout-item:last-child {
        order: -1
    }
}

@media screen and (max-width: 767.9px) {

    .l-grid_layout.m-3_9 .l-grid_layout-item:last-child,
    .l-grid_layout.m-4_8 .l-grid_layout-item:last-child,
    .l-grid_layout.m-3_3_6 .l-grid_layout-item:last-child {
        order: -1
    }
}

.l-grid_layout.m-3_9.m-mobile_reversed .l-grid_layout-item:last-child,
.l-grid_layout.m-4_8.m-mobile_reversed .l-grid_layout-item:last-child,
.l-grid_layout.m-3_3_6.m-mobile_reversed .l-grid_layout-item:last-child {
    order: 1
}

@media screen and (min-width: 768px) {
    .l-grid_layout.m-5_up .l-grid_layout-content {
        grid-template-columns: repeat(5, calc(20% - 16px))
    }
}

@media screen and (min-width: 768px) {
    .l-grid_layout.m-5_up .l-grid_layout-item {
        grid-column: auto
    }
}

.l-plp_grid-pd_preview {
    display: none
}

.l-plp_grid-banner {
    display: flex
}

.l-plp_grid-banner_content {
    display: flex;
    flex-grow: 1
}

.l-page-container.m-edit_mode .l-plp_grid-pd_preview {
    align-items: center;
    background-color: #f3f4f3;
    display: flex;
    font-size: 16px;
    justify-content: center
}

.l-static_page.m-full_width {
    margin-bottom: 0;
    max-width: 1920px;
    padding-left: 0;
    padding-right: 0
}

.l-static_page-title {
    margin: 0 auto;
    max-width: 1920px;
    padding-left: 88px;
    padding-right: 88px;
    font-family: inherit;
    font-size: 52px;
    font-weight: 350;
    line-height: 68px;
}



@media screen and (min-width: 1024px)and (max-width: 1366.9px) {
    .l-static_page-title {
        padding-left: 20px;
        padding-right: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-static_page-title {
        padding-left: 32px;
        padding-right: 32px
    }
}

@media screen and (max-width: 767.9px) {
    .l-static_page-title {
        padding-left: 15px;
        padding-right: 15px
    }
}

@media screen and (max-width: 1023.9px) {
    .l-static_page-title {
        font-size: 42px;
        letter-spacing: -0.0075em;
        line-height: 58px
    }
}

@media screen and (max-width: 767.9px) {
    .l-static_page-title {
        text-align: center
    }
}

.l-page-review {
    position: absolute;
    top: 0;
    width: 100%
}

.l-page.m-edit_mode {
    padding-top: 75px
}

@media screen and (max-width: 1023.9px) {
    .l-page.m-edit_mode {
        padding-top: 116px
    }
}

.l-page.m-transparent_header .l-page-content {
    margin-top: -178px
}

@media screen and (max-width: 1023.9px) {
    .l-page.m-transparent_header .l-page-content {
        margin-top: -64px
    }
}

.l-page.m-transparent_header .b-menu_bar,
.l-page.m-transparent_header .l-header {
    background-color: rgba(0, 0, 0, 0);
    transition: background-color cubic-bezier(0.3, 0.46, 0.45, 0.94) .2s
}

@media screen and (min-width: 1024px) {
    .l-page.m-transparent_header .l-header-inner {
        position: relative;
        z-index: 16
    }
}

@media screen and (min-width: 1024px) {
    .l-page.m-transparent_header.b-header_stuck .b-menu_bar {
        background-color: #fff
    }
}

.l-page.m-transparent_header.b-header_stuck .l-header {
    background-color: #fff
}

.l-page.m-transparent_header .l-header-bottom_promo,
.l-page.m-transparent_header .b-header_category {
    display: none
}

@media screen and (min-width: 1024px) {

    .l-page.m-transparent_header .b-menu_bar-link[aria-expanded=true],
    .l-page.m-transparent_header .b-menu_bar-link:hover,
    .l-page.m-transparent_header .b-menu_bar-item:hover .b-menu_bar-link {
        background-color: rgba(0, 0, 0, 0)
    }
}

@media screen and (min-width: 1024px) {
    .l-page.m-transparent_header .b-menu_bar-flyout {
        display: block;
        opacity: 0;
        transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s;
        visibility: hidden
    }
}

@media screen and (min-width: 1024px) {
    .l-page.m-transparent_header .l-header.m-menu_bar-active {
        background-color: #fff;
        position: relative;
        transition: background-color cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s;
        z-index: 16
    }
}

@media screen and (min-width: 1024px) {
    .l-page.m-transparent_header .l-header.m-menu_bar-active~.b-menu_panel {
        background-color: #fff;
        transition: background-color cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s
    }
}

@media screen and (min-width: 1024px) {
    .l-page.m-transparent_header .l-header.m-menu_bar-active~.b-menu_panel .b-menu_bar-flyout[aria-hidden=false] {
        opacity: 1;
        visibility: visible
    }
}

.b-promo_caption {
    align-self: center
}

.b-promo_caption-title {
    font-family: inherit;
    font-size: 46px;
    font-weight: 350;
    line-height: 48px;
    margin: 0 0 20px;
}

@media screen and (max-width: 1023.9px) {
    .b-promo_caption-title {
        font-size: 38px;
        line-height: 48px
    }
}

.b-promo_caption-subtitle {
    font-weight: 136;
    margin: 0 0 20px
}

.b-promo_caption-actions {
    align-items: baseline;
    display: inline-flex;
    flex-wrap: wrap;
    margin: 24px -16px 0
}

.b-promo_caption-actions a {
    margin: 0 16px 12px
}

.b-promo_info_box {
    display: grid;
    background: #c3d6ee;
    color: #095c9c
}

@media screen and (min-width: 1367px) {
    .b-promo_info_box {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end]
    }
}

@media screen and (min-width: 1024px)and (max-width: 1366.9px) {
    .b-promo_info_box {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end]
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-promo_info_box {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end]
    }
}

@media screen and (max-width: 767.9px) {
    .b-promo_info_box {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end]
    }
}

.b-promo_info_box-caption {
    grid-column: 1/grid-end;
    grid-row: 1/2;
    padding: 48px 16px;
    text-align: center
}

.b-promo_box {
    display: grid
}

@media screen and (min-width: 1367px) {
    .b-promo_box {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end]
    }
}

@media screen and (min-width: 1024px)and (max-width: 1366.9px) {
    .b-promo_box {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end]
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-promo_box {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end]
    }
}

@media screen and (max-width: 767.9px) {
    .b-promo_box {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end]
    }
}

.b-promo_box-picture {
    background: #f3f4f3;
    display: block;
    overflow: hidden;
    padding-bottom: 44.2477876106%;
    position: relative;
    width: 100%;
    grid-column: grid-start/grid-end;
    grid-row: 1/2;
    z-index: -1
}

@media screen and (max-width: 767.9px) {
    .b-promo_box-picture {
        padding-bottom: 100%
    }
}

.b-promo_box-picture img,
.b-promo_box-picture video {
    border: none;
    bottom: 0;
    color: #f3f4f3;
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%
}

.b-promo_box-caption {
    grid-column: grid-start/grid-end;
    grid-row: 1/2;
    padding: 48px 0;
    text-align: center
}

.b-promo_box.m-full_bleed {
    grid-template-columns: auto;
    height: 100%
}

.b-promo_box.m-full_bleed .b-promo_box-picture {
    grid-column: 1/2;
    grid-row: 1/2;
    padding-bottom: 32.3162274619%
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-promo_box.m-full_bleed .b-promo_box-picture {
        padding-bottom: 58.3333333333%
    }
}

@media screen and (max-width: 767.9px) {
    .b-promo_box.m-full_bleed .b-promo_box-picture {
        padding-bottom: 115.2%
    }
}

.b-promo_box.m-full_bleed .b-promo_box-inner {
    display: grid;
    margin: 0 auto;
    max-width: 1920px;
    padding-left: 88px;
    padding-right: 88px;
    grid-column: 1/2;
    grid-row: 1/2;
    margin-bottom: 16px;
    margin-top: 16px;
    width: 100%
}

@media screen and (min-width: 1367px) {
    .b-promo_box.m-full_bleed .b-promo_box-inner {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end]
    }
}

@media screen and (min-width: 1024px)and (max-width: 1366.9px) {
    .b-promo_box.m-full_bleed .b-promo_box-inner {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end]
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-promo_box.m-full_bleed .b-promo_box-inner {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end]
    }
}

@media screen and (max-width: 767.9px) {
    .b-promo_box.m-full_bleed .b-promo_box-inner {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end]
    }
}

@media screen and (min-width: 1024px)and (max-width: 1366.9px) {
    .b-promo_box.m-full_bleed .b-promo_box-inner {
        padding-left: 20px;
        padding-right: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-promo_box.m-full_bleed .b-promo_box-inner {
        padding-left: 32px;
        padding-right: 32px
    }
}

@media screen and (max-width: 767.9px) {
    .b-promo_box.m-full_bleed .b-promo_box-inner {
        padding-left: 15px;
        padding-right: 15px
    }
}

@media screen and (max-width: 767.9px) {
    .b-promo_box:not(.m-full_bleed) .b-promo_box-caption {
        padding-left: 15px;
        padding-right: 15px
    }
}

.b-promo_box.m-caption_left .b-promo_box-caption {
    text-align: start
}

@media screen and (min-width: 768px) {
    .b-promo_box.m-caption_left .b-promo_box-caption {
        grid-column: 2/span 5
    }
}

.b-promo_box.m-caption_right .b-promo_box-caption {
    text-align: start
}

@media screen and (min-width: 768px) {
    .b-promo_box.m-caption_right .b-promo_box-caption {
        grid-column: 8/span 5
    }
}

@media screen and (min-width: 768px) {
    .b-promo_box.m-caption_center .b-promo_box-caption {
        grid-column: 3/span 8
    }
}

.b-promo_box.m-caption_offcenter .b-promo_box-caption {
    text-align: start
}

@media screen and (min-width: 768px) {
    .b-promo_box.m-caption_offcenter .b-promo_box-caption {
        grid-column: 7/span 5
    }
}

@media screen and (max-width: 767.9px) {
    .b-promo_box.m-text_below:not(.m-full_bleed) .b-promo_box-picture {
        grid-column: grid-start/grid-end
    }
}

@media screen and (max-width: 767.9px) {
    .b-promo_box.m-text_below:not(.m-full_bleed) .b-promo_box-caption {
        grid-column: grid-start/grid-end;
        grid-row: 2/3;
        padding: 16px 0
    }
}

@media screen and (max-width: 767.9px) {
    .b-promo_box.m-text_below.m-full_bleed {
        display: block
    }

    .b-promo_box.m-text_below.m-full_bleed .b-promo_box-picture {
        grid-column: grid-start/grid-end
    }

    .b-promo_box.m-text_below.m-full_bleed .b-promo_box-inner {
        grid-column: grid-start/grid-end;
        grid-row: 2/3
    }

    .b-promo_box.m-text_below.m-full_bleed .b-promo_box-caption {
        padding: 16px 0
    }
}

@keyframes growIn {
    0% {
        opacity: 0;
        transform: scale(0.8)
    }

    50% {
        transform: scale(1.2)
    }

    100% {
        opacity: 1;
        transform: scale(1)
    }
}

.b-personalized_cards {
    display: grid
}

@media screen and (min-width: 1367px) {
    .b-personalized_cards {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end]
    }
}

@media screen and (min-width: 1024px)and (max-width: 1366.9px) {
    .b-personalized_cards {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end]
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-personalized_cards {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end]
    }
}

@media screen and (max-width: 767.9px) {
    .b-personalized_cards {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end]
    }
}

.b-personalized_cards h1,
.b-personalized_cards .title-h1 {
    font-family: inherit;
    font-size: 52px;
    font-weight: 350;
    line-height: 68px;
}

@media screen and (max-width: 1023.9px) {

    .b-personalized_cards h1,
    .b-personalized_cards .title-h1 {
        font-size: 42px;
        letter-spacing: -0.0075em;
        line-height: 58px
    }
}

.b-personalized_cards h2 {
    font-family: inherit;
    font-size: 46px;
    font-weight: 350;
    line-height: 48px;
}

@media screen and (max-width: 1023.9px) {
    .b-personalized_cards h2 {
        font-size: 38px;
        line-height: 48px
    }
}

.b-personalized_cards h3 {
    font-family: inherit;
    font-size: 36px;
    font-weight: 350;
    line-height: 40px;
}

@media screen and (max-width: 1023.9px) {
    .b-personalized_cards h3 {
        font-size: 30px;
        line-height: 40px
    }
}

.b-personalized_cards h4 {
    font-size: 26px;
    font-weight: 136;
    letter-spacing: .01em;
    line-height: 32px
}

@media screen and (max-width: 1023.9px) {
    .b-personalized_cards h4 {
        font-size: 24px;
        letter-spacing: .002em;
        line-height: 30px;
        font-weight: bold;
    }
}

.b-personalized_cards h5 {
    font-family: inherit;
    font-size: 24px;
    font-weight: 350;
    line-height: 32px;


}

@media screen and (max-width: 1023.9px) {
    .b-personalized_cards h5 {
        font-size: 22px;
        line-height: 28px
    }
}

.b-personalized_cards h6 {
    font-size: 18px;
    font-weight: 136;
    letter-spacing: -0.0055em;
    line-height: 24px
}

@media screen and (max-width: 1023.9px) {
    .b-personalized_cards h6 {
        font-size: 18px;
        line-height: 24px
    }
}

.b-personalized_cards-headline {
    grid-column: 1/13;
    grid-row: 1;
    text-align: center
}

.b-personalized_cards-list_wrapper {
    grid-column: 1/13;
    grid-row: 2;
    margin: 0 -10px
}

.b-personalized_cards-item {
    background: #fff;
    border: 1px solid #535353;
    display: flex;
    flex-wrap: wrap;
    margin: 0 10px;
    width: calc(33.3% - 20px)
}

@media screen and (max-width: 1023.9px) {
    .b-personalized_cards-item {
        flex: 0 0 360px;
        width: 360px
    }
}

@media screen and (max-width: 767.9px) {
    .b-personalized_cards-item {
        max-width: calc(100vw - 30px)
    }
}

.b-personalized_cards-image {
    flex: 1
}

.b-personalized_cards-content {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 10px 8px 10px 20px
}

.b-personalized_cards-pagination {
    display: none;
    justify-content: center;
    margin-top: 20px
}

@media screen and (max-width: 1023.9px) {
    .b-personalized_cards-pagination {
        display: flex
    }
}

.b-personalized_cards.m-inited .b-personalized_cards-pagination {
    display: flex
}

.b-personalized_cards.m-no_scroll .b-personalized_cards-pagination {
    display: none
}

.b-personalized_cards-page {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
    display: block;
    height: 20px;
    line-height: 20px;
    position: relative;
    text-align: center;
    width: 20px
}

.b-personalized_cards-page::before {
    background-color: #535353;
    border: 1px solid #fff;
    border-radius: 12px;
    content: "";
    display: inline-block;
    height: 12px;
    left: 50%;
    margin-inline-start: -6px;
    margin-top: -6px;
    position: absolute;
    top: 50%;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s;
    transition-property: width, height, margin;
    width: 12px
}

.b-personalized_cards-page.m-current::before {
    background-color: #753bbd;
    content: "";
    height: 12px;
    margin-inline-start: -6px;
    margin-top: -6px;
    width: 12px
}

.b-edit_mode {
    pointer-events: none
}

.b-image_tile.m-horizontal_left,
.b-image_tile.m-horizontal_center,
.b-image_tile.m-horizontal_right {
    display: flex;
    flex-wrap: wrap;
    width: 100%
}

.b-image_tile.m-horizontal_left {
    justify-content: flex-start
}

.b-image_tile.m-horizontal_center {
    justify-content: center
}

.b-image_tile.m-horizontal_right {
    justify-content: flex-end
}

.b-image_tile.m-vertical_top {
    align-self: flex-start
}

.b-image_tile.m-vertical_middle {
    align-self: center
}

.b-image_tile.m-vertical_bottom {
    align-self: flex-end
}

@media screen and (max-width: 767.9px) {
    .b-image_tile.m-vertical_fill {
        align-items: flex-start
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-image_tile.m-vertical_fill-md {
        align-self: stretch
    }
}

@media screen and (max-width: 767.9px) {
    .b-image_tile.m-vertical_fill-sm {
        align-self: stretch
    }
}

.b-image_tile-container {
    display: flex;
    width: 100%
}

/* @media screen and (min-width: 768px) {
    .b-image_tile-container {
        max-width:var(--max-width)
    }
} */

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-image_tile.m-vertical_fill-md .b-image_tile-container {
        height: 100%
    }
}

@media screen and (max-width: 767.9px) {
    .b-image_tile.m-vertical_fill-sm .b-image_tile-container {
        height: 100%
    }
}

.b-image_tile-picture {
    background: #f3f4f3;
    display: block;
    height: 0;
    overflow: hidden;
    /* padding-bottom: var(--bg-padding, 100%); */
    position: relative;
    width: 100%
}

/* @media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-image_tile-picture {
        padding-bottom:var(--bg-padding-tablet)
    }
}

@media screen and (max-width: 767.9px) {
    .b-image_tile-picture {
        padding-bottom:var(--bg-padding-mobile)
    }
} */

.b-image_tile-picture img {
    border: none;
    bottom: 0;
    color: #f3f4f3;
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    /* object-position: var(--img-obj-position) */
}

/* @media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-image_tile-picture img {
        object-position:var(--img-md-obj-position, var(--img-obj-position))
    }
}

@media screen and (max-width: 767.9px) {
    .b-image_tile-picture img {
        object-position:var(--img-sm-obj-position, var(--img-obj-position))
    }
} */

@media screen and (min-width: 1024px) {
    .b-image_tile-picture.m-fixed_height-lg_up {
        height: auto;
        padding-bottom: 0
    }
}

@media screen and (min-width: 1024px) {
    .b-image_tile-picture.m-fixed_height-lg_up img {
        position: initial
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-image_tile-picture.m-fixed_height-md {
        height: auto;
        padding-bottom: 0
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-image_tile-picture.m-fixed_height-md img {
        position: initial
    }
}

@media screen and (max-width: 767.9px) {
    .b-image_tile-picture.m-fixed_height-sm {
        height: auto;
        padding-bottom: 0
    }
}

@media screen and (max-width: 767.9px) {
    .b-image_tile-picture.m-fixed_height-sm img {
        position: initial
    }
}

.b-text_block {
    /* background-color: var(--bg-text-block, transparent); */
    width: 100%
}

.b-text_block.m-vertical_top {
    align-items: flex-start
}

.b-text_block.m-vertical_middle {
    align-items: center
}

.b-text_block.m-vertical_bottom {
    align-items: flex-end
}

.b-text_block.m-vertical_fill {
    align-self: stretch
}

.b-text_block.m-horizontal_left,
.b-text_block.m-horizontal_center,
.b-text_block.m-horizontal_right {
    display: flex;
    flex-wrap: wrap
}

.b-text_block.m-horizontal_left {
    justify-content: flex-start
}

.b-text_block.m-horizontal_center {
    justify-content: center
}

.b-text_block.m-horizontal_right {
    justify-content: flex-end
}

.b-text_block h1,
.b-text_block .title-h1 {
    font-family: 'Georgia', 'Times New Roman', sans-serif;
    font-size: 52px;
    font-weight: 350;
    line-height: 68px;
}

@media screen and (max-width: 1023.9px) {

    .b-text_block h1,
    .b-text_block .title-h1 {
        font-size: 42px;
        letter-spacing: -0.0075em;
        line-height: 58px
    }
}

.b-text_block h2 {
    font-family: inherit;
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;

}

@media screen and (max-width: 1023.9px) {
    .b-text_block h2 {
        font-size: 38px;
        line-height: 48px
    }
}

.b-text_block h3 {
    font-family: inherit;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;

}

@media screen and (max-width: 1023.9px) {
    .b-text_block h3 {
        font-size: 28px;
        line-height: 40px
    }
}

.b-text_block h4 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: .01em;
    line-height: 32px
}

@media screen and (max-width: 1023.9px) {
    .b-text_block h4 {
        font-size: 24px;
        letter-spacing: .002em;
        line-height: 30px
    }
}

.b-text_block h5 {
    font-family: inherit;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px
}

@media screen and (max-width: 1023.9px) {
    .b-text_block h5 {
        font-size: 22px;
        line-height: 28px
    }
}

.b-text_block h6 {
    font-size: 18px;
    font-weight: 136;
    letter-spacing: -0.0055em;
    line-height: 24px
}

@media screen and (max-width: 1023.9px) {
    .b-text_block h6 {
        font-size: 18px;
        line-height: 24px
    }
}

.b-text_block h1,
.b-text_block .title-h1,
.b-text_block h2,
.b-text_block h3,
.b-text_block h4,
.b-text_block h5,
.b-text_block h6 {
    margin-bottom: 8px;
}

.b-text_block ul,
.b-text_block ol {
    display: block;
    margin-bottom: 16px;
    padding-left: 20px
}

.b-text_block li {
    display: list-item;
    margin-bottom: 4px
}

.b-text_block ul {
    list-style: disc outside
}

.b-text_block ol {
    list-style: decimal outside
}

.b-text_block p {
    font-weight: 400;
    margin-bottom: 4px;
}


.b-text_block blockquote {
    border-left: 4px solid #535353;
    font-style: italic;
    margin: 0 0 16px;
    padding: 0 0 0 20px;
}

.b-text_block *:last-child {
    margin-bottom: 0;
}


@media screen and (max-width: 767.9px) {
    .b-text_block.m-padding_top_sm .b-text_block-container {
        padding-top: 40px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023.9px) {
    .b-text_block.m-padding_top_sm .b-text_block-container {
        padding-top: 40px;
    }
}

@media screen and (min-width: 1024px) {
    .b-text_block.m-padding_top_sm .b-text_block-container {
        padding-top: 40px;
    }
}

@media screen and (max-width: 767.9px) {
    .b-text_block.m-padding_top_lg .b-text_block-container {
        padding-top: 60px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-text_block.m-padding_top_lg .b-text_block-container {
        padding-top: 60px
    }
}

@media screen and (min-width: 1024px) {
    .b-text_block.m-padding_top_lg .b-text_block-container {
        padding-top: 60px
    }
}

@media screen and (max-width: 767.9px) {
    .b-text_block.m-padding_right_sm .b-text_block-container {
        padding-right: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-text_block.m-padding_right_sm .b-text_block-container {
        padding-right: 40px
    }
}

@media screen and (min-width: 1024px) {
    .b-text_block.m-padding_right_sm .b-text_block-container {
        padding-right: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .b-text_block.m-padding_right_lg .b-text_block-container {
        padding-right: 60px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-text_block.m-padding_right_lg .b-text_block-container {
        padding-right: 60px
    }
}

@media screen and (min-width: 1024px) {
    .b-text_block.m-padding_right_lg .b-text_block-container {
        padding-right: 60px
    }
}

@media screen and (max-width: 767.9px) {
    .b-text_block.m-padding_bottom_sm .b-text_block-container {
        padding-bottom: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-text_block.m-padding_bottom_sm .b-text_block-container {
        padding-bottom: 40px
    }
}

@media screen and (min-width: 1024px) {
    .b-text_block.m-padding_bottom_sm .b-text_block-container {
        padding-bottom: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .b-text_block.m-padding_bottom_lg .b-text_block-container {
        padding-bottom: 60px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-text_block.m-padding_bottom_lg .b-text_block-container {
        padding-bottom: 60px
    }
}

@media screen and (min-width: 1024px) {
    .b-text_block.m-padding_bottom_lg .b-text_block-container {
        padding-bottom: 60px
    }
}

@media screen and (max-width: 767.9px) {
    .b-text_block.m-padding_left_sm .b-text_block-container {
        padding-left: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-text_block.m-padding_left_sm .b-text_block-container {
        padding-left: 40px
    }
}

@media screen and (min-width: 1024px) {
    .b-text_block.m-padding_left_sm .b-text_block-container {
        padding-left: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .b-text_block.m-padding_left_lg .b-text_block-container {
        padding-left: 60px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-text_block.m-padding_left_lg .b-text_block-container {
        padding-left: 60px
    }
}

@media screen and (min-width: 1024px) {
    .b-text_block.m-padding_left_lg .b-text_block-container {
        padding-left: 60px
    }
}

.l-grid_layout.m-2_up .b-text_block {
    position: relative
}

@media screen and (min-width: 1024px) {
    .l-grid_layout.m-2_up .b-text_block {
        margin: 0 -2px
    }
}

@media screen and (max-width: 1023.9px) {
    .l-grid_layout.m-2_up .b-text_block {
        margin: -2px 0
    }
}

.b-text_block-container {
    width: 100%
}

/* @media screen and (min-width: 768px) {
    .b-text_block-container {
        width:var(--width, auto)
    }
} */

.b-text_block-container *:last-child {
    margin-bottom: 0
}

.b-text_block pre {
    white-space: pre-line
}

@media screen and (min-width: 1024px) {
    .b-text_block .hidden-lg {
        display: none
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-text_block .hidden-md {
        display: none
    }
}

@media screen and (max-width: 767.9px) {
    .b-text_block .hidden-sm {
        display: none
    }
}

.b-actions {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 12px;
    margin: 10px 0 0;
    /* padding: var(--cta-padding-top, 0) var(--cta-padding-right, 0) var(--cta-padding-bottom, 0) var(--cta-padding-left, 0); */
    position: relative;
    width: 100%
}

@media screen and (max-width: 767.9px) {
    .b-actions.m-padding_top_sm {
        padding-top: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-actions.m-padding_top_sm {
        padding-top: 40px
    }
}

@media screen and (min-width: 1024px) {
    .b-actions.m-padding_top_sm {
        padding-top: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .b-actions.m-padding_top_lg {
        padding-top: 60px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-actions.m-padding_top_lg {
        padding-top: 60px
    }
}

@media screen and (min-width: 1024px) {
    .b-actions.m-padding_top_lg {
        padding-top: 60px
    }
}

@media screen and (max-width: 767.9px) {
    .b-actions.m-padding_right_sm {
        padding-right: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-actions.m-padding_right_sm {
        padding-right: 40px
    }
}

@media screen and (min-width: 1024px) {
    .b-actions.m-padding_right_sm {
        padding-right: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .b-actions.m-padding_right_lg {
        padding-right: 60px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-actions.m-padding_right_lg {
        padding-right: 60px
    }
}

@media screen and (min-width: 1024px) {
    .b-actions.m-padding_right_lg {
        padding-right: 60px
    }
}

@media screen and (max-width: 767.9px) {
    .b-actions.m-padding_bottom_sm {
        padding-bottom: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-actions.m-padding_bottom_sm {
        padding-bottom: 40px
    }
}

@media screen and (min-width: 1024px) {
    .b-actions.m-padding_bottom_sm {
        padding-bottom: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .b-actions.m-padding_bottom_lg {
        padding-bottom: 60px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-actions.m-padding_bottom_lg {
        padding-bottom: 60px
    }
}

@media screen and (min-width: 1024px) {
    .b-actions.m-padding_bottom_lg {
        padding-bottom: 60px
    }
}

@media screen and (max-width: 767.9px) {
    .b-actions.m-padding_left_sm {
        padding-left: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-actions.m-padding_left_sm {
        padding-left: 40px
    }
}

@media screen and (min-width: 1024px) {
    .b-actions.m-padding_left_sm {
        padding-left: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .b-actions.m-padding_left_lg {
        padding-left: 60px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-actions.m-padding_left_lg {
        padding-left: 60px
    }
}

@media screen and (min-width: 1024px) {
    .b-actions.m-padding_left_lg {
        padding-left: 60px
    }
}

.b-actions:last-child:first-child {
    margin-top: 0
}

/* .b-actions-item {
    width: var(--button-width, auto)
} */

.b-actions-container.m-horizontal_left,
.b-actions-container.m-horizontal_center,
.b-actions-container.m-horizontal_right {
    display: flex;
    flex-wrap: wrap;
    width: 100%
}

.b-actions-container.m-horizontal_left {
    justify-content: flex-start
}

.b-actions-container.m-horizontal_center {
    justify-content: center
}

.b-actions-container.m-horizontal_right {
    justify-content: flex-end
}

.b-actions-container.m-vertical_top {
    align-self: flex-start
}

.b-actions-container.m-vertical_middle {
    align-self: center
}

.b-actions-container.m-vertical_bottom {
    align-self: flex-end
}

/* .b-actions-container .b-button {
    width: var(--button-width, auto)
} */

.b-actions-container .b-button.m-primary:not(.m-small) .b-button-icon_left,
.b-actions-container .b-button.m-primary_dark_mode:not(.m-small) .b-button-icon_left,
.b-actions-container .b-button.m-secondary .b-button-icon_left,
.b-actions-container .b-button.m-secondary_dark_mode .b-button-icon_left {
    height: 24px;
    margin-right: 8px;
    margin-left: 0;
    width: 24px;
    align-items: center;
    background-color: rgba(255, 255, 255, .2);
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    padding: 6px;
    margin-left: -8px
}

.b-actions-container .b-button.m-primary:not(.m-small) .b-button-icon_left svg,
.b-actions-container .b-button.m-primary_dark_mode:not(.m-small) .b-button-icon_left svg,
.b-actions-container .b-button.m-secondary .b-button-icon_left svg,
.b-actions-container .b-button.m-secondary_dark_mode .b-button-icon_left svg {
    height: 100%;
    width: 100%
}

.b-actions-container .b-button.m-primary:not(.m-small) .b-button-icon_right,
.b-actions-container .b-button.m-primary_dark_mode:not(.m-small) .b-button-icon_right,
.b-actions-container .b-button.m-secondary .b-button-icon_right,
.b-actions-container .b-button.m-secondary_dark_mode .b-button-icon_right {
    height: 24px;
    margin-left: 8px;
    margin-right: 0;
    width: 24px;
    align-items: center;
    background-color: rgba(255, 255, 255, .2);
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    padding: 6px;
    margin-right: -8px
}

.b-actions-container .b-button.m-primary:not(.m-small) .b-button-icon_right svg,
.b-actions-container .b-button.m-primary_dark_mode:not(.m-small) .b-button-icon_right svg,
.b-actions-container .b-button.m-secondary .b-button-icon_right svg,
.b-actions-container .b-button.m-secondary_dark_mode .b-button-icon_right svg {
    height: 100%;
    width: 100%
}

.b-actions-container .b-button.m-small .b-button-icon_left,
.b-actions-container .b-button.m-link .b-button-icon_left {
    height: 12px;
    margin-right: 10px;
    margin-left: 0;
    width: 12px;
    background: rgba(0, 0, 0, 0);
    border-radius: 0;
    display: block;
    line-height: 12px;
    padding: 0
}

.b-actions-container .b-button.m-small .b-button-icon_left svg,
.b-actions-container .b-button.m-link .b-button-icon_left svg {
    height: 100%;
    width: 100%
}

.b-actions-container .b-button.m-small .b-button-icon_right,
.b-actions-container .b-button.m-link .b-button-icon_right {
    height: 12px;
    margin-left: 10px;
    margin-right: 0;
    width: 12px;
    background: rgba(0, 0, 0, 0);
    border-radius: 0;
    display: block;
    line-height: 12px;
    padding: 0
}

.b-actions-container .b-button.m-small .b-button-icon_right svg,
.b-actions-container .b-button.m-link .b-button-icon_right svg {
    height: 100%;
    width: 100%
}

.b-actions-items {
    display: flex;
    grid-gap: 12px
}

.b-actions-items .b-actions-container .b-button {
    white-space: nowrap
}

.b-actions.m-rows_1 {
    display: flex;
    overflow: hidden;
    overflow-behavior: contain;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    -ms-scroll-chaining: none;
    scrollbar-width: none;
    overflow-x: auto;
    overscroll-behavior-x: none;
    scroll-snap-type: x mandatory;
    flex-direction: column;
    margin-bottom: -10px;
    padding-bottom: 10px
}

.b-actions.m-rows_1::-webkit-scrollbar {
    display: none
}

/* .b-banner .b-actions.m-rows_1 {
    margin-left: calc(var(--outer-content-padding-left, 0px)*-1 + var(--inner-content-padding-left, 8px)*-1);
    width: calc(100% + var(--outer-content-padding-left, 0px) + var(--inner-content-padding-left, 8px) + var(--inner-content-padding-right, 8px))
} */

.b-actions.m-rows_2 {
    display: flex;
    overflow: hidden;
    overflow-behavior: contain;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    -ms-scroll-chaining: none;
    scrollbar-width: none;
    overflow-x: auto;
    overscroll-behavior-x: none;
    scroll-snap-type: x mandatory;
    flex-direction: column;
    margin-bottom: -10px;
    padding-bottom: 10px
}

.b-actions.m-rows_2::-webkit-scrollbar {
    display: none
}

/* .b-banner .b-actions.m-rows_2 {
    margin-left: calc(var(--outer-content-padding-left, 0px)*-1 + var(--inner-content-padding-left, 8px)*-1);
    width: calc(100% + var(--outer-content-padding-left, 0px) + var(--inner-content-padding-left, 8px) + var(--inner-content-padding-right, 8px))
} */

.b-actions.m-one-cta .b-actions-item {
    width: 100%
}

/* .b-actions.m-one-cta .b-button {
    width: var(--button-width, auto)
} */

.b-actions.m-column {
    flex-direction: column
}

.b-actions.m-center {
    align-items: center;
    justify-content: center
}

.b-actions.m-right {
    align-items: flex-end;
    justify-content: flex-end
}

.b-banner {
    display: flex;
    width: 100%
}

.b-banner.m-vertical_top {
    align-items: flex-start
}

.b-banner.m-vertical_middle {
    align-items: center
}

.b-banner.m-vertical_bottom {
    align-items: flex-end
}

.b-banner.m-vertical_fill {
    align-self: stretch
}

.b-banner.b-banner-link {
    display: block
}

@media screen and (max-width: 767.9px) {
    .b-banner.m-vertical_fill {
        align-items: flex-start
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner.m-vertical_fill-md {
        align-items: stretch
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner.m-vertical_fill-sm {
        align-items: stretch
    }
}

.b-banner-inner {
    display: grid;
    width: 100%
}

@media screen and (min-width: 1367px) {
    .b-banner-inner {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end]
    }
}

@media screen and (min-width: 1024px)and (max-width: 1366.9px) {
    .b-banner-inner {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end]
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-inner {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end]
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-inner {
        grid-gap: 20px;
        grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end]
    }
}

.b-banner .b-banner-inner {
    grid-gap: 0
}

/* .b-banner-inner.m-border {
    border: 1px solid var(--border-color, #535353)
} */

.b-carousel .b-banner-inner {
    height: 100%
}

@media screen and (max-width: 1023.9px) {
    .b-banner-inner.m-diagonal-sm .b-banner_content {
        /* padding:var(--inner-content-padding, 32px); */
        width: 300px
    }
}

@media screen and (min-width: 1024px) {

    .b-banner-inner.m-diagonal_right,
    .b-banner-inner.m-diagonal_left {
        padding-bottom: 40px
    }

    .b-banner-inner.m-diagonal_right .b-banner-picture,
    .b-banner-inner.m-diagonal_left .b-banner-picture {
        grid-column: 1/11
    }

    .b-banner-inner.m-diagonal_right .b-banner_content,
    .b-banner-inner.m-diagonal_left .b-banner_content {
        /* padding: var(--inner-content-padding, 32px); */
        width: 300px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-inner.m-diagonal_left .b-banner-picture {
        grid-column: 3/13
    }
}

@media screen and (min-width: 768px) {

    .b-banner-inner.m-image_left,
    .b-banner-inner.m-image_right {
        /* background:var(--bg-content); */
        display: flex
    }

    .b-banner-inner.m-image_left .b-banner-picture,
    .b-banner-inner.m-image_right .b-banner-picture {
        flex: 1
    }

    /* .b-banner-inner.m-image_left .b-banner-caption,.b-banner-inner.m-image_right .b-banner-caption {
        width: var(--content-width, auto)
    } */

    .b-banner-inner.m-image_left .b-banner_content,
    .b-banner-inner.m-image_right .b-banner_content {
        padding: 20px;
        width: 100%
    }
}

/* @media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-inner.m-image_left-sm .b-banner-caption,.b-banner-inner.m-image_right-sm .b-banner-caption {
        width:var(--content-width-tablet, auto)
    }
} */

@media screen and (max-width: 767.9px) {

    .b-banner-inner.m-image_left-sm,
    .b-banner-inner.m-image_right-sm {
        /* background:var(--bg-content); */
        display: flex
    }

    .b-banner-inner.m-image_left-sm .b-banner-picture,
    .b-banner-inner.m-image_right-sm .b-banner-picture {
        flex: 1
    }

    /* .b-banner-inner.m-image_left-sm .b-banner-caption,.b-banner-inner.m-image_right-sm .b-banner-caption {
        width: var(--content-width-mobile, auto)
    } */

    .b-banner-inner.m-image_left-sm .b-banner_content,
    .b-banner-inner.m-image_right-sm .b-banner_content {
        padding: 20px;
        width: 100%
    }
}

.b-banner-inner.m-image_right .b-banner-picture {
    flex: 1;
    order: 2
}

.b-banner-caption {
    grid-column: grid-start/grid-end;
    grid-row: 1/2;
    /* padding: var(--outer-content-padding-top, 0) var(--outer-content-padding-right, 0) var(--outer-content-padding-bottom, 0) var(--outer-content-padding-left, 0) */
}

.b-banner-caption.m-horizontal_left,
.b-banner-caption.m-horizontal_center,
.b-banner-caption.m-horizontal_right {
    display: flex;
    flex-wrap: wrap;
    width: 100%
}

.b-banner-caption.m-horizontal_left {
    justify-content: flex-start
}

.b-banner-caption.m-horizontal_center {
    justify-content: center
}

.b-banner-caption.m-horizontal_right {
    justify-content: flex-end
}

.b-banner-caption.m-vertical_top {
    align-self: flex-start
}

.b-banner-caption.m-vertical_middle {
    align-self: center
}

.b-banner-caption.m-vertical_bottom {
    align-self: flex-end
}

@media screen and (max-width: 767.9px) {
    .b-banner-caption.m-sm-vertical_top {
        align-self: flex-start
    }

    .b-banner-caption.m-sm-vertical_middle {
        align-self: center
    }

    .b-banner-caption.m-sm-vertical_bottom {
        align-self: flex-end
    }
}

/* @media screen and (min-width: 1024px)and (max-width: 1366.9px) {
    .b-banner-caption {
        padding:var(--outer-content-padding-top, 0) var(--outer-content-padding-right, 0) var(--outer-content-padding-bottom, 0) var(--outer-content-padding-left, 0)
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-caption {
        padding:var(--outer-content-padding-top, 0) var(--outer-content-padding-right, 0) var(--outer-content-padding-bottom, 0) var(--outer-content-padding-left, 0)
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-caption {
        padding:var(--outer-content-padding-top, 0) var(--outer-content-padding-right, 0) var(--outer-content-padding-bottom, 0) var(--outer-content-padding-left, 0)
    }
} */

.b-banner-caption.m-beneath_image {
    display: flex
}

@media screen and (min-width: 768px) {
    .b-banner-caption.m-beneath_image {
        height: 100%;
        padding: 0
    }
}

.b-banner-caption.m-beneath_image .b-banner_content {
    align-items: center;
    display: flex;
    flex-direction: column
}

.b-banner-caption.m-beneath_bottom {
    grid-row: 2;
    padding: 0
}

.b-banner-caption.m-beneath_bottom .b-banner_content {
    width: 100%
}

.b-banner-caption.m-beneath_right {
    flex-direction: row-reverse
}

@media screen and (max-width: 767.9px) {
    .b-banner-caption.m-sm-beneath_image {
        grid-row: 2;
        padding: 0
    }

    .b-banner-caption.m-sm-beneath_image .b-banner_content {
        width: 100%
    }
}

@media screen and (max-width: 1023.9px) {
    .b-banner-caption.m-diagonal-sm {
        grid-row: 2/3;
        margin-top: -24px;
        /* padding: var(--outer-content-padding, 0); */
        place-self: end center
    }
}

@media screen and (min-width: 1024px) {

    .b-banner-caption.m-diagonal_right,
    .b-banner-caption.m-diagonal_left {
        grid-column: 9/13;
        /* padding: var(--outer-content-padding, 0); */
        place-self: end;
        position: relative;
        top: 40px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-caption.m-diagonal_left {
        grid-column: 1/4
    }
}

@media screen and (min-width: 768px) {

    .b-banner-caption.m-image_left,
    .b-banner-caption.m-image_right {
        align-items: center;
        display: flex;
        height: 100%;
        width: 50%
    }
}

@media screen and (max-width: 767.9px) {

    .b-banner-caption.m-image_left-sm,
    .b-banner-caption.m-image_right-sm {
        align-items: center;
        display: flex;
        height: 100%;
        width: 50%
    }
}

.l-plp_grid-banner .b-banner-caption {
    padding: 44px 16px
}

.l-plp_grid-banner:nth-of-type(2) .b-banner-caption {
    padding: 24px
}

.l-grid_layout.m-centered .b-banner-caption.m-beneath_image {
    padding: 0
}

/* .b-banner-caption img {
    object-position: var(--content-obj-position)
} */

.b-banner-picture {
    background: #f3f4f3;
    display: block;
    overflow: hidden;
    padding-bottom: 44.2477876106%;
    position: relative;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    grid-column: grid-start/grid-end;
    grid-row: 1/2;
    /* padding-bottom: var(--bg-padding, 44.2477876106%); */
    z-index: 0
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_margin_top_sm {
        margin-top: 10px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_margin_top_sm {
        margin-top: 10px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_margin_top_sm {
        margin-top: 10px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_margin_top_md {
        margin-top: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_margin_top_md {
        margin-top: 20px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_margin_top_md {
        margin-top: 20px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_margin_top_lg {
        margin-top: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_margin_top_lg {
        margin-top: 40px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_margin_top_lg {
        margin-top: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_margin_top_xl {
        margin-top: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_margin_top_xl {
        margin-top: 20px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_margin_top_xl {
        margin-top: 80px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_margin_right_sm {
        margin-right: 10px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_margin_right_sm {
        margin-right: 10px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_margin_right_sm {
        margin-right: 10px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_margin_right_md {
        margin-right: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_margin_right_md {
        margin-right: 20px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_margin_right_md {
        margin-right: 20px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_margin_right_lg {
        margin-right: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_margin_right_lg {
        margin-right: 40px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_margin_right_lg {
        margin-right: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_margin_right_xl {
        margin-right: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_margin_right_xl {
        margin-right: 20px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_margin_right_xl {
        margin-right: 80px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_margin_bottom_sm {
        margin-bottom: 10px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_margin_bottom_sm {
        margin-bottom: 10px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_margin_bottom_sm {
        margin-bottom: 10px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_margin_bottom_md {
        margin-bottom: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_margin_bottom_md {
        margin-bottom: 20px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_margin_bottom_md {
        margin-bottom: 20px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_margin_bottom_lg {
        margin-bottom: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_margin_bottom_lg {
        margin-bottom: 40px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_margin_bottom_lg {
        margin-bottom: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_margin_bottom_xl {
        margin-bottom: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_margin_bottom_xl {
        margin-bottom: 20px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_margin_bottom_xl {
        margin-bottom: 80px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_margin_left_sm {
        margin-left: 10px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_margin_left_sm {
        margin-left: 10px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_margin_left_sm {
        margin-left: 10px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_margin_left_md {
        margin-left: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_margin_left_md {
        margin-left: 20px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_margin_left_md {
        margin-left: 20px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_margin_left_lg {
        margin-left: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_margin_left_lg {
        margin-left: 40px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_margin_left_lg {
        margin-left: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_margin_left_xl {
        margin-left: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_margin_left_xl {
        margin-left: 20px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_margin_left_xl {
        margin-left: 80px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_padding_top_sm img {
        padding-top: 10px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_padding_top_sm img {
        padding-top: 10px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_padding_top_sm img {
        padding-top: 10px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_padding_top_md img {
        padding-top: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_padding_top_md img {
        padding-top: 20px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_padding_top_md img {
        padding-top: 20px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_padding_top_lg img {
        padding-top: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_padding_top_lg img {
        padding-top: 40px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_padding_top_lg img {
        padding-top: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_padding_top_xl img {
        padding-top: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_padding_top_xl img {
        padding-top: 20px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_padding_top_xl img {
        padding-top: 80px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_padding_right_sm img {
        padding-right: 10px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_padding_right_sm img {
        padding-right: 10px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_padding_right_sm img {
        padding-right: 10px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_padding_right_md img {
        padding-right: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_padding_right_md img {
        padding-right: 20px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_padding_right_md img {
        padding-right: 20px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_padding_right_lg img {
        padding-right: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_padding_right_lg img {
        padding-right: 40px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_padding_right_lg img {
        padding-right: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_padding_right_xl img {
        padding-right: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_padding_right_xl img {
        padding-right: 20px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_padding_right_xl img {
        padding-right: 80px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_padding_bottom_sm img {
        padding-bottom: 10px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_padding_bottom_sm img {
        padding-bottom: 10px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_padding_bottom_sm img {
        padding-bottom: 10px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_padding_bottom_md img {
        padding-bottom: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_padding_bottom_md img {
        padding-bottom: 20px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_padding_bottom_md img {
        padding-bottom: 20px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_padding_bottom_lg img {
        padding-bottom: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_padding_bottom_lg img {
        padding-bottom: 40px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_padding_bottom_lg img {
        padding-bottom: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_padding_bottom_xl img {
        padding-bottom: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_padding_bottom_xl img {
        padding-bottom: 20px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_padding_bottom_xl img {
        padding-bottom: 80px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_padding_left_sm img {
        padding-left: 10px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_padding_left_sm img {
        padding-left: 10px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_padding_left_sm img {
        padding-left: 10px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_padding_left_md img {
        padding-left: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_padding_left_md img {
        padding-left: 20px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_padding_left_md img {
        padding-left: 20px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_padding_left_lg img {
        padding-left: 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_padding_left_lg img {
        padding-left: 40px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_padding_left_lg img {
        padding-left: 40px
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-background_padding_left_xl img {
        padding-left: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-background_padding_left_xl img {
        padding-left: 20px
    }
}

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-background_padding_left_xl img {
        padding-left: 80px
    }
}

/* @media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture {
        padding-bottom:var(--bg-padding-tablet, --bg-padding)
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture {
        padding-bottom:var(--bg-padding-mobile, --bg-padding)
    }
} */

@media screen and (min-width: 1024px) {
    .b-banner-picture.m-fixed_height-lg_up {
        /* min-height:var(--min-height, auto); */
        padding-bottom: 0
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture.m-fixed_height-md {
        /* min-height:var(--min-height-tablet, auto); */
        padding-bottom: 0
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture.m-fixed_height-sm {
        /* min-height:var(--min-height-mobile, auto); */
        padding-bottom: 0
    }
}

.b-banner-picture.m-rounded_corner {
    border-radius: 16px;
    overflow: hidden
}

.b-banner-picture.m-circle {
    border-radius: 50%;
    overflow: hidden;
    padding-bottom: 100%
}

.b-banner-picture.m-square {
    overflow: hidden;
    padding-bottom: 100%
}

.b-banner-picture img,
.b-banner-picture video {
    border: none;
    bottom: 0;
    color: #f3f4f3;
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%
}

/* .b-banner-picture img {
    object-position: var(--img-obj-position)
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner-picture img {
        object-position:var(--img-md-obj-position, var(--img-obj-position))
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner-picture img {
        object-position:var(--img-sm-obj-position, var(--img-obj-position))
    }
} */

@media screen and (max-width: 767.9px) {
    .b-banner-disable_mobile {
        display: none
    }
}

.b-banner .b-text_block {
    position: relative;
    width: 100%
}

.b-banner .b-banner-inner.b-banner-link {
    height: 100%
}

.b-banner_content {
    /* background-color: var(--bg-content, transparent); */
    max-width: 100%;
    /* padding: var(--inner-content-padding-top, 8px) var(--inner-content-padding-right, 8px) var(--inner-content-padding-bottom, 8px) var(--inner-content-padding-left, 8px); */
    position: relative;
    /* width: var(--content-width, auto) */
}

/* @media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-banner_content {
        width:var(--content-width-tablet, auto)
    }
}

@media screen and (max-width: 767.9px) {
    .b-banner_content {
        width:var(--content-width-mobile, auto)
    }
} */

/* .b-carousel-body .b-banner_content {
    padding: var(--inner-content-padding-top, 10px) var(--inner-content-padding-right, 10px) var(--inner-content-padding-bottom, 10px) var(--inner-content-padding-left, 10px)
} */

.b-banner_content-image {
    width: 100%
}

.b-banner_content-picture {
    background: #f3f4f3;
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0
}

.b-banner_content-picture img {
    border: none;
    bottom: 0;
    color: #f3f4f3;
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%
}

.b-product_ext {
    display: flex;
    width: 100%
}

.b-product_ext.m-vertical_top {
    align-items: flex-start
}

.b-product_ext.m-vertical_middle {
    align-items: center
}

.b-product_ext.m-vertical_bottom {
    align-items: flex-end
}

.b-product_ext.m-vertical_fill {
    align-self: stretch
}

.b-product_ext .b-product_tile {
    height: auto;
    padding-bottom: 0;
    width: 100%
}

/* .b-product_ext .b-product_tile img {
    object-position: var(--img-obj-position)
} */

/* .b-product_ext .b-product_tile-description {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px
} */

.b-product_ext .b-product_tile-no_available {
    margin-bottom: 0
}

@media screen and (max-width: 767.9px) {
    .b-product_ext .b-button {
        width: 100%
    }
}

.b-product_ext .b-product_tile-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 12px
}

.b-carousel-item .b-product_ext .b-product_tile-actions {
    gap: 10px
}

.b-category_tile.m-horizontal_left,
.b-category_tile.m-horizontal_center,
.b-category_tile.m-horizontal_right {
    display: flex;
    flex-wrap: wrap;
    width: 100%
}

.b-category_tile.m-horizontal_left {
    justify-content: flex-start
}

.b-category_tile.m-horizontal_center {
    justify-content: center
}

.b-category_tile.m-horizontal_right {
    justify-content: flex-end
}

.b-category_tile.m-vertical_top {
    align-self: flex-start
}

.b-category_tile.m-vertical_middle {
    align-self: center
}

.b-category_tile.m-vertical_bottom {
    align-self: flex-end
}

.b-category_tile-inner {
    display: grid;
    width: 100%
}

/* @media screen and (min-width: 768px) {
    .b-category_tile-inner {
        max-width:var(--max-width, 100%)
    }
} */

.b-category_tile-link,
.b-category_tile-picture,
.b-category_tile-content {
    grid-column: 1/2;
    grid-row: 1/2
}

/* .b-category_tile-link,.b-category_tile-picture,.b-category_tile img {
    border-radius: var(--aspect-radio-border, 0)
} */

.b-category_tile-picture {
    display: block;
    height: 0;
    overflow: hidden;
    /* padding-bottom: var(--bg-padding, 100%); */
    position: relative;
    width: 100%
}

.b-category_tile-picture img {
    border: none;
    bottom: 0;
    color: #f3f4f3;
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    /* object-position: var(--img-obj-position) */
}

.b-category_tile-link .b-category_tile-picture {
    transform: scale(1);
    transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s
}

.b-category_tile-link:hover .b-category_tile-picture {
    transform: scale(1.05)
}

/* .b-category_tile-picture.m-border {
    border: 3px solid var(--border-color, #535353)
} */

.b-category_tile-content {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
    padding: 48px 12px;
    pointer-events: none;
    text-decoration: underline;
    z-index: 1
}

.b-category_tile-content.m-vertical_top {
    align-items: flex-start
}

.b-category_tile-content.m-vertical_middle {
    align-items: center
}

.b-category_tile-content.m-vertical_bottom {
    align-items: flex-end
}

.b-category_tile-content.m-vertical_fill {
    align-self: stretch
}

.b-category_tile-content a {
    pointer-events: auto
}

.b-category_tile-content.m-vertical_beneath-image {
    grid-row: 2/3;
    padding: 25px 12px
}

.b-category_tile .b-text_block {
    width: 100%
}

.b-header_ext.m-horizontal_left,
.b-header_ext.m-horizontal_center,
.b-header_ext.m-horizontal_right {
    display: flex;
    flex-wrap: wrap;
    width: 100%
}

.b-header_ext.m-horizontal_left {
    justify-content: flex-start
}

.b-header_ext.m-horizontal_center {
    justify-content: center
}

.b-header_ext.m-horizontal_right {
    justify-content: flex-end
}

.b-header_ext.m-vertical_top {
    align-self: flex-start
}

.b-header_ext.m-vertical_middle {
    align-self: center
}

.b-header_ext.m-vertical_bottom {
    align-self: flex-end
}

.b-header_ext-wrapper {
    display: flex
}

/* @media screen and (min-width: 768px) {
    .b-header_ext-wrapper {
        max-width:var(--max-width, none)
    }
} */

.b-header_ext-wrapper.m-pictute_before .b-header_ext-img {
    margin-right: 20px
}

.b-header_ext-wrapper.m-pictute_after {
    flex-direction: row-reverse
}

.b-header_ext-wrapper.m-pictute_after .b-header_ext-img {
    margin-left: 20px
}

.b-header_ext-wrapper.m-pictute_above {
    flex-direction: column
}

.b-header_ext-wrapper.m-pictute_above .b-header_ext-img {
    align-self: center;
    margin-bottom: 20px
}

.b-header_ext-picture {
    display: block;
    height: 48px;
    position: relative;
    width: 48px
}

.b-header_ext-picture img {
    border: none;
    bottom: 0;
    color: #f3f4f3;
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%
}

.b-header_ext .b-text_block {
    align-self: center
}

.l-grid_layout .b-carousel.m-products {
    margin: 0
}

.b-carousel_ext.m-vertical_top {
    align-items: flex-start
}

.b-carousel_ext.m-vertical_middle {
    align-items: center
}

.b-carousel_ext.m-vertical_bottom {
    align-items: flex-end
}

.b-carousel_ext.m-vertical_fill {
    align-self: stretch
}

@media screen and (min-width: 768px) {
    .b-carousel_ext .b-header_ext.m-horizontal_center {
        padding-left: 68px
    }
}

.b-carousel_ext .b-carousel {
    display: flex;
    flex-direction: column;
    margin: 0 -10px;
    opacity: 0;
    position: initial
}

@media screen and (max-width: 767.9px) {
    .b-carousel_ext .b-carousel {
        margin-top: 8px
    }
}

.b-carousel_ext .b-carousel-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
    padding: 0 10px
}

.b-carousel_ext .b-carousel-header_title {
    flex-grow: 1
}

.b-carousel_ext .b-carousel-nav {
    display: flex;
    margin-right: -14px;
    opacity: 0;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s;
    transition-property: visibility, opacity;
    visibility: hidden
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-carousel_ext .b-carousel-nav {
        display: none
    }
}

.b-carousel_ext .b-carousel-nav.m-custom {
    display: flex;
    justify-content: space-between;
    left: 0;
    margin-left: 20px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2
}

@media screen and (max-width: 1366.9px) {
    .b-carousel_ext .b-carousel-nav.m-custom {
        margin-right: 20px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-carousel_ext .b-carousel-nav.m-custom {
        display: none
    }
}

.b-carousel_ext .b-carousel-body {
    display: flex
}

.b-carousel_ext .b-carousel-track {
    width: 100%
}

.b-carousel_ext .b-carousel-item {
    display: flex;
    padding: 0 8px
}

.b-carousel_ext .b-carousel-item.m-vertical_top {
    align-self: flex-start
}

.b-carousel_ext .b-carousel-item.m-vertical_middle {
    align-self: center
}

.b-carousel_ext .b-carousel-item.m-vertical_bottom {
    align-self: flex-end
}

.b-carousel_ext .b-carousel-item.m-sm_6 {
    flex: 0 0 100%;
    max-width: 100%
}

.b-carousel_ext .b-carousel-item.m-sm_5 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%
}

.b-carousel_ext .b-carousel-item.m-sm_4 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%
}

.b-carousel_ext .b-carousel-item.m-sm_3 {
    flex: 0 0 50%;
    max-width: 50%
}

.b-carousel_ext .b-carousel-item.m-sm_2 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
}

.b-carousel_ext .b-carousel-item.m-sm_1 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
}

@media screen and (min-width: 768px) {
    .b-carousel_ext .b-carousel-item.m-md_6 {
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media screen and (min-width: 768px) {
    .b-carousel_ext .b-carousel-item.m-md_5 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }
}

@media screen and (min-width: 768px) {
    .b-carousel_ext .b-carousel-item.m-md_4 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }
}

@media screen and (min-width: 768px) {
    .b-carousel_ext .b-carousel-item.m-md_3 {
        flex: 0 0 50%;
        max-width: 50%
    }
}

@media screen and (min-width: 768px) {
    .b-carousel_ext .b-carousel-item.m-md_2 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }
}

@media screen and (min-width: 768px) {
    .b-carousel_ext .b-carousel-item.m-md_1 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }
}

@media screen and (min-width: 1024px) {
    .b-carousel_ext .b-carousel-item.m-lg_12 {
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media screen and (min-width: 1024px) {
    .b-carousel_ext .b-carousel-item.m-lg_11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }
}

@media screen and (min-width: 1024px) {
    .b-carousel_ext .b-carousel-item.m-lg_10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }
}

@media screen and (min-width: 1024px) {
    .b-carousel_ext .b-carousel-item.m-lg_9 {
        flex: 0 0 75%;
        max-width: 75%
    }
}

@media screen and (min-width: 1024px) {
    .b-carousel_ext .b-carousel-item.m-lg_8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }
}

@media screen and (min-width: 1024px) {
    .b-carousel_ext .b-carousel-item.m-lg_7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }
}

@media screen and (min-width: 1024px) {
    .b-carousel_ext .b-carousel-item.m-lg_6 {
        flex: 0 0 50%;
        max-width: 50%
    }
}

@media screen and (min-width: 1024px) {
    .b-carousel_ext .b-carousel-item.m-lg_5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }
}

@media screen and (min-width: 1024px) {
    .b-carousel_ext .b-carousel-item.m-lg_4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }
}

@media screen and (min-width: 1024px) {
    .b-carousel_ext .b-carousel-item.m-lg_3 {
        flex: 0 0 25%;
        max-width: 25%
    }
}

@media screen and (min-width: 1024px) {
    .b-carousel_ext .b-carousel-item.m-lg_2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }
}

@media screen and (min-width: 1024px) {
    .b-carousel_ext .b-carousel-item.m-lg_1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }
}

.b-carousel_ext .b-carousel.m-inited {
    opacity: 1;
    position: relative
}

@media screen and (min-width: 768px) {

    .b-carousel_ext .b-carousel.m-inited.m-next_visible .b-carousel-nav,
    .b-carousel_ext .b-carousel.m-inited.m-prev_visible .b-carousel-nav {
        opacity: 1;
        visibility: visible
    }
}

.b-carousel_ext .b-carousel.m-no_scroll .b-carousel-track.m-grabbing {
    cursor: default;
    -webkit-user-select: auto;
    user-select: auto
}

.b-carousel_ext.m-fullscreen_slider {
    margin-bottom: -10px;
    overflow: initial
}

.b-carousel_ext.m-fullscreen_slider .b-carousel {
    height: 100%;
    position: relative
}

.b-carousel_ext.m-fullscreen_slider .b-carousel-body {
    height: 100%;
    margin-right: calc(-88px + 10px)
}

@media screen and (min-width: 1024px)and (max-width: 1366.9px) {
    .b-carousel_ext.m-fullscreen_slider .b-carousel-body {
        margin-right: calc(-20px + 10px)
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-carousel_ext.m-fullscreen_slider .b-carousel-body {
        margin-right: calc(-32px + 10px)
    }
}

@media screen and (max-width: 767.9px) {
    .b-carousel_ext.m-fullscreen_slider .b-carousel-body {
        margin-right: calc(-15px + 10px)
    }
}

.b-carousel_ext.m-fullscreen_slider .b-carousel-track {
    padding-bottom: 10px
}

.b-carousel_ext.m-fullscreen_slider .b-banner-inner {
    grid-template-rows: max-content
}

.b-carousel_ext.m-hide_gutters .b-carousel {
    margin: 0
}

.b-carousel_ext.m-hide_gutters .b-carousel-item {
    padding: 0
}

.b-carousel_ext.mmr .b-carousel-pagination {
    margin-top: 0
}

.b-carousel_ext.mmr .b-carousel-nav.m-custom {
    position: relative;
    opacity: 1;
    visibility: visible
}

.b-carousel_ext.mmr.m-fullscreen_slider .b-carousel-body {
    margin-right: 0
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-carousel_ext .b-promo_box-picture.m-general {
        padding-bottom: 44.2477876106%
    }
}

.b-carousel_ext .b-text_block {
    width: 100%
}

@media screen and (min-width: 768px) {
    .b-carousel.m-side-nav {
        flex-direction: row
    }

    .b-carousel.m-side-nav .b-carousel-body {
        flex-grow: 1
    }

    .b-carousel.m-side-nav .b-carousel-header_title {
        flex-grow: 0
    }

    .b-carousel.m-side-nav .b-carousel-nav {
        display: flex;
        margin: 0 0 0 -14px
    }
}

.b-carousel.m-side-nav .b-carousel-header {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%
}

@media screen and (min-width: 768px) {
    .b-carousel.m-side-nav .b-carousel-header {
        flex-shrink: 0;
        width: 25%
    }
}

.b-carousel.m-side-nav .b-header_ext-wrapper {
    margin-bottom: 40px
}

@media screen and (max-width: 767.9px) {
    .b-carousel.m-side-nav .b-header_ext-wrapper {
        margin-bottom: 0
    }
}

.b-product_editorial {
    display: flex;
    width: 100%
}

.b-product_editorial.m-vertical_top {
    align-items: flex-start
}

.b-product_editorial.m-vertical_middle {
    align-items: center
}

.b-product_editorial.m-vertical_bottom {
    align-items: flex-end
}

.b-product_editorial.m-vertical_fill {
    align-self: stretch
}

@media screen and (max-width: 767.9px) {
    .b-product_editorial .b-button {
        width: 100%
    }
}

.b-product_editorial .b-product_tile {
    display: grid;
    grid-column-gap: 20px;
    grid-template: "image sold_out" "image title" "image description" "image price" "image promotion" "image swatches" "image rating" "image actions";
    grid-template-columns: 1fr minmax(auto, 50%);
    grid-template-rows: min-content;
    position: relative;
    width: 100%
}

@media screen and (max-width: 767.9px) {
    .b-product_editorial .b-product_tile {
        display: flex;
        flex-direction: column
    }
}

.b-product_editorial .b-product_tile-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end
}

.b-product_editorial .b-product_tile-top {
    grid-area: image
}

@media screen and (min-width: 768px) {
    .b-product_editorial .b-product_tile-top {
        margin-bottom: 0
    }
}

.b-product_editorial .b-product_tile-price {
    grid-area: price
}

.b-product_editorial .b-product_tile-title {
    grid-area: title
}

/* .b-product_editorial .b-product_tile-description {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    grid-area: description
} */

.b-product_editorial .b-product_tile_swatches {
    grid-area: swatches
}

.b-product_editorial .b-product_tile .b-promotion {
    grid-area: promotion
}

.b-product_editorial .b-product_tile .b-rating {
    grid-area: rating
}

.b-product_editorial .b-product_tile-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    grid-area: actions;
    margin-top: 12px
}

.b-carousel-item .b-product_editorial .b-product_tile-actions {
    gap: 10px
}

.b-product_editorial .b-product_tile-no_available {
    align-self: flex-end;
    grid-area: sold_out
}

@media screen and (max-width: 767.9px) {
    .b-product_editorial .b-product_tile-no_available {
        align-self: flex-start
    }
}

/* .b-product_editorial .b-product_tile img {
    object-position: var(--img-obj-position)
} */

.b-carousel_simple {
    display: flex
}

.b-carousel_simple.m-vertical_top {
    align-items: flex-start
}

.b-carousel_simple.m-vertical_middle {
    align-items: center
}

.b-carousel_simple.m-vertical_bottom {
    align-items: flex-end
}

.b-carousel_simple.m-vertical_fill {
    align-self: stretch
}

.b-carousel_simple .b-carousel {
    display: flex;
    flex-direction: column;
    margin: 0;
    max-width: 100%;
    width: 100%
}

.b-carousel_simple .b-carousel-ctrl {
    background-color: rgba(0, 0, 0, 0);
    top: 50%
}

@media screen and (max-width: 767.9px) {
    .b-carousel_simple .b-carousel-ctrl {
        display: none
    }
}

.b-carousel_simple .b-carousel-ctrl[disabled] {
    opacity: .5;
    z-index: 1
}

.b-carousel_simple .b-carousel-pagination {
    justify-content: initial;
    margin: 0 auto;
    min-height: 34px;
    order: 1;
    overflow: hidden;
    position: initial;
    width: 114px
}

.b-carousel_simple .b-carousel-pagination_content {
    display: inline-flex;
    left: 40px;
    position: relative;
    transition: left cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s
}

.b-carousel_simple .b-carousel-pagination_dot {
    border: none;
    color: rgba(0, 0, 0, 0);
    cursor: pointer;
    fill: #535353;
    height: 34px;
    margin-inline-end: 6px;
    padding: 0;
    width: 34px
}

@media not all and (pointer: coarse) {
    .b-carousel_simple .b-carousel-pagination_dot:hover {
        fill: #095c9c
    }
}

.b-carousel_simple .b-carousel-pagination_dot:last-child {
    margin-inline-end: 0
}

.b-carousel_simple .b-carousel-pagination_dot[aria-disabled=true] {
    cursor: initial
}

.b-carousel_simple .b-carousel-pagination_dot svg {
    transform: scale(0.6);
    transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s
}

.b-carousel_simple .b-carousel-pagination_dot.m-current {
    fill: #095c9c
}

.b-carousel_simple .b-carousel-pagination_dot.m-current svg {
    transform: scale(1)
}

.b-carousel_simple .b-carousel-track.m-mousemove_navigation {
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory
}

.b-carousel_simple .b-carousel-item {
    margin-inline-end: 1px;
    max-width: 100%;
    min-width: 100%
}

.b-carousel_simple .b-carousel-item:last-child {
    margin-inline-end: 0
}

.b-carousel_simple .b-carousel-item .b-text_block {
    height: 100%;
    position: relative
}

.b-carousel_simple.m-type-numeric .b-carousel {
    display: flex;
    flex-direction: column
}

.b-carousel_simple.m-type-numeric .b-carousel-actions {
    align-items: center;
    display: flex;
    order: 2;
    padding-top: 20px;
    position: relative
}

@media screen and (max-width: 767.9px) {
    .b-carousel_simple.m-type-numeric .b-carousel-actions {
        padding-top: 12px
    }
}

.b-carousel_simple.m-type-numeric .b-carousel-ctrl {
    display: inline-block;
    margin-top: 0;
    position: relative;
    transform: none
}

.b-carousel_simple.m-type-numeric .b-carousel-ctrl svg {
    height: 19px;
    width: 19px
}

.b-carousel_simple.m-type-numeric .b-carousel-ctrl.m-prev {
    margin-left: -16px
}

.b-carousel_simple.m-type-numeric .b-carousel-pagination {
    display: none
}

.b-carousel_simple.m-type-numeric .b-carousel-numeric_pagination {
    margin-top: 2px;
    text-align: center;
    width: 50px
}

.b-carousel_simple.m-type-standard .b-carousel {
    padding: 0 60px
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-carousel_simple.m-type-standard .b-carousel {
        padding: 0 44px
    }
}

@media screen and (max-width: 767.9px) {
    .b-carousel_simple.m-type-standard .b-carousel {
        padding: 0
    }
}

.b-carousel_simple.m-type-standard .b-carousel-actions {
    display: block;
    left: 0;
    position: absolute;
    right: 0
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-carousel_simple.m-type-standard .b-carousel-actions {
        left: 44px;
        right: 44px
    }
}

@media screen and (min-width: 1024px) {
    .b-carousel_simple.m-type-standard .b-carousel-actions {
        left: 60px;
        right: 60px
    }
}

.b-carousel_simple.m-type-standard .b-carousel-actions::before {
    content: "";
    display: block;
    padding-bottom: 133.3333333333%
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-carousel_simple.m-type-standard .b-carousel-ctrl.m-prev {
        left: -44px
    }
}

@media screen and (min-width: 1024px) {
    .b-carousel_simple.m-type-standard .b-carousel-ctrl.m-prev {
        left: -60px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-carousel_simple.m-type-standard .b-carousel-ctrl.m-next {
        right: -44px
    }
}

@media screen and (min-width: 1024px) {
    .b-carousel_simple.m-type-standard .b-carousel-ctrl.m-next {
        right: -60px
    }
}

.b-carousel_simple.m-hide_controls .b-carousel-actions,
.b-carousel_simple.m-hide_controls .b-carousel-pagination {
    display: none
}

.b-carousel_simple.m-hide_controls .b-carousel-track.m-grabbing {
    cursor: default;
    -webkit-user-select: auto;
    user-select: auto
}

.l-grid_layout-item.m-lg_3 .b-carousel_simple.m-type-standard .b-carousel {
    padding: 0
}

.l-grid_layout-item.m-lg_3 .b-carousel_simple.m-type-standard .b-carousel-actions {
    display: none
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout-item.m-md_4 .b-carousel_simple.m-type-standard .b-carousel {
        padding: 0
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .l-grid_layout-item.m-md_4 .b-carousel_simple.m-type-standard .b-carousel-actions {
        display: none
    }
}

.b-hero_carousel_ext {
    display: flex
}

.b-hero_carousel_ext.m-vertical_top {
    align-items: flex-start
}

.b-hero_carousel_ext.m-vertical_middle {
    align-items: center
}

.b-hero_carousel_ext.m-vertical_bottom {
    align-items: flex-end
}

.b-hero_carousel_ext.m-vertical_fill {
    align-self: stretch
}

.b-hero_carousel_ext.m-vertical_fill .b-hero_carousel-track,
.b-hero_carousel_ext.m-vertical_fill .b-banner.m-vertical_fill,
.b-hero_carousel_ext.m-vertical_fill .b-hero_carousel-item>.b-text_block {
    height: 100%
}

.b-hero_carousel_ext.m-vertical_fill .b-hero_carousel-item {
    display: flex;
    flex-direction: column
}

.b-hero_carousel_ext.m-fade .m-initialized .b-hero_carousel-item {
    opacity: 0;
    transform: none;
    transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s, visibility 0s .4s
}

.b-hero_carousel_ext.m-fade .m-initialized .b-hero_carousel-item.m-current {
    opacity: 1;
    transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s, visibility 0s
}

.b-hero_carousel_ext .b-hero_carousel {
    width: 100%
}

.b-video_banner {
    width: 100%
}

.b-video_banner.m-vertical_top {
    align-self: flex-start
}

.b-video_banner.m-vertical_middle {
    align-self: center
}

.b-video_banner.m-vertical_bottom {
    align-self: flex-end
}

.b-video_banner-container {
    display: grid;
    grid-template-columns: auto;
    margin: 0 auto;
    overflow: hidden;
    position: relative
}

.b-video_banner-container::after {
    /* background-color: var(--bg-videoBanner-overlay, transparent); */
    content: "";
    height: 100%;
    left: 0;
    /* opacity: var(--opacity, 100%); */
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%
}

.b-video_banner-inner {
    grid-column: 1/2;
    grid-row: 1/2;
    /* padding-bottom: var(--bg-padding, 100%); */
    position: relative
}

/* @media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-video_banner-inner {
        padding-bottom:var(--bg-padding-tablet, 100%)
    }
}

@media screen and (max-width: 767.9px) {
    .b-video_banner-inner {
        padding-bottom:var(--bg-padding-mobile, 100%)
    }
} */

.b-video_banner-item {
    border: 0;
    height: 100%;
    left: 0;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 100%
}

.b-video_banner-item[aria-hidden=true] {
    visibility: hidden
}

.b-video_banner-text_block {
    display: grid;
    grid-column: 1/2;
    grid-row: 1/2
}

.b-video_banner-caption {
    align-items: center;
    display: flex;
    flex-direction: column;
    left: 50%;
    padding: 16px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%
}

.b-video_banner-title {
    font-family: inherit;
    font-size: 36px;
    font-weight: 350;
    line-height: 40px;
    margin-bottom: 20px
}

@media screen and (max-width: 1023.9px) {
    .b-video_banner-title {
        font-size: 30px;
        line-height: 40px
    }
}

.b-video_banner-ctrl {
    background-color: #fff;
    border-radius: 50%;
    height: 50px;
    pointer-events: none;
    position: relative;
    width: 50px;
    z-index: 1
}

.b-video_banner-ctrl::before {
    border-bottom: 9px solid rgba(0, 0, 0, 0);
    border-left: 17px solid #000;
    border-top: 9px solid rgba(0, 0, 0, 0);
    content: "";
    left: 19px;
    position: absolute;
    top: 17px
}

.b-video_banner-poster {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: visibility cubic-bezier(0.3, 0.46, 0.45, 0.94) .2s .3s;
    z-index: 1
}

@media screen and (min-width: 768px) {
    .b-video_banner-poster.m-hidden_md-up {
        display: none
    }
}

.b-video_banner .b-image_tile-picture {
    height: 100%
}

/* @media screen and (max-width: 767.9px) {
    .b-video_banner .b-image_tile-picture {
        padding-bottom:var(--bg-padding-tablet, 100%)
    }
}

@media screen and (max-width: 767.9px) {
    .b-video_banner .b-image_tile-picture {
        padding-bottom:var(--bg-padding-mobile, 100%)
    }
} */

.b-video_banner.m-vertical_fill {
    align-items: stretch
}

.b-video_banner.m-vertical_fill .b-video_banner-item.m-external {
    object-fit: cover;
    object-position: center
}

.b-video_banner.m-vertical_fill .b-image_tile-picture {
    padding-bottom: 0
}

.b-video_banner.m-vertical_fill .b-video_banner-container {
    height: 100%
}

.b-video_banner .b-text_block {
    background-color: rgba(0, 0, 0, 0);
    height: 100%;
    left: 0;
    pointer-events: none;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 1
}

@media screen and (min-width: 1024px) {
    .b-video_banner .b-text_block {
        padding: 60px 40px
    }
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-video_banner .b-text_block {
        padding: 60px 32px
    }
}

@media screen and (max-width: 767.9px) {
    .b-video_banner .b-text_block {
        padding: 40px 12px
    }
}

/* .b-video_banner .b-text_block-container {
    background-color: var(--bg-text-block, transparent)
} */

.b-video_banner .b-text_block .b-button {
    pointer-events: auto
}

.b-action_banner {
    align-self: flex-start;
    overflow: hidden;
    position: relative
}

.b-action_banner-spots {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.b-action_banner.m-edit_mode .b-action_banner-spot {
    height: 100%;
    pointer-events: none;
    position: absolute;
    width: 100%
}

.b-hot_spot.m-edit_mode {
    pointer-events: all
}

@media screen and (min-width: 768px) {
    .b-hot_spot.m-hidden-md_up {
        display: none
    }
}

@media screen and (max-width: 767.9px) {
    .b-hot_spot.m-hidden-sm {
        display: none
    }
}

.b-hot_spot-btn {
    cursor: pointer;
    height: 24px;
    /* left: var(--left-position, 0); */
    position: absolute;
    /* top: var(--top-position, 0); */
    transform: translate(-50%, -50%);
    width: 24px
}

/* @media screen and (max-width: 767.9px) {
    .b-hot_spot-btn {
        left:var(--left-position-sm);
        top: var(--top-position-sm)
    }
} */

@media screen and (min-width: 1024px) {
    .b-hot_spot-btn:hover::before {
        opacity: 1;
        padding: 16px
    }

    .b-hot_spot-btn:hover::after {
        opacity: .4;
        padding: 24px
    }
}

.b-hot_spot-btn::before,
.b-hot_spot-btn::after {
    /* border: 1px solid var(--bg-color, #ffffff); */
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s, padding cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s;
    width: 100%
}

.b-hot_spot-btn.m-active::before {
    opacity: 1;
    padding: 16px
}

.b-hot_spot-btn.m-active::after {
    opacity: .4;
    padding: 24px
}

.b-hot_spot-sign {
    /* background-color: var(--bg-color, #ffffff); */
    border-radius: 50%;
    display: block;
    height: 100%;
    position: relative
}

.b-hot_spot-sign::before,
.b-hot_spot-sign::after {
    /* background-color: var(--bg-color, #ffffff); */
    content: "";
    display: block;
    filter: grayscale(1) contrast(100) invert(1);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%)
}

.b-hot_spot-sign::after {
    height: 1px;
    width: 7px
}

.b-hot_spot-sign::before {
    height: 7px;
    width: 1px
}

.b-hot_spot-popup {
    /* background-color: var(--bg-color, #ffffff); */
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s, visibility cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s;
    visibility: hidden;
    width: 488px;
    z-index: 2
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-hot_spot-popup {
        width: 50%
    }
}

@media screen and (max-width: 767.9px) {
    .b-hot_spot-popup {
        width: 80%
    }
}

.b-hot_spot-popup[aria-hidden=false] {
    transform: translateX(0);
    transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s, visibility 0s;
    visibility: visible
}

.b-hot_spot-scrollbox {
    height: 100%;
    overflow-y: auto;
    padding: 44px 60px;
    width: 100%
}

@media screen and (min-width: 1367px) {
    .b-hot_spot-scrollbox {
        padding: 92px 88px
    }
}

@media screen and (max-width: 767.9px) {
    .b-hot_spot-scrollbox {
        padding-left: 16px;
        padding-right: 16px
    }
}

.b-hot_spot-scrollbox::before {
    /* border-bottom: 44px solid var(--bg-color, #ffffff);
    border-top: 44px solid var(--bg-color, #ffffff); */
    bottom: 0;
    content: "";
    left: 60px;
    pointer-events: none;
    position: absolute;
    right: 60px;
    top: 0;
    z-index: 1
}

@media screen and (min-width: 1367px) {
    .b-hot_spot-scrollbox::before {
        border-width: 92px
    }
}

@media screen and (max-width: 767.9px) {
    .b-hot_spot-scrollbox::before {
        left: 16px;
        right: 16px
    }
}

.b-hot_spot-scrollbox::-webkit-scrollbar {
    width: 2px
}

.b-hot_spot-scrollbox::-webkit-scrollbar-thumb {
    background-color: #535353
}

.b-hot_spot-content {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    justify-content: center;
    min-height: 100%
}

.b-hot_spot-content>:last-child {
    margin-bottom: 0
}

.b-hot_spot-content .b-badges {
    z-index: 0
}

.b-hot_spot-close {
    /* color: var(--bg-color, #ffffff); */
    cursor: pointer;
    height: 48px;
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
    z-index: 2
}

@media screen and (max-width: 767.9px) {
    .b-hot_spot-close {
        top: -8px
    }
}

.b-hot_spot-close svg {
    filter: grayscale(1) contrast(100) invert(1)
}

.b-hot_spot-overlay {
    background-color: rgba(41, 48, 53, .4);
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s, visibility cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s;
    visibility: hidden;
    z-index: 1
}

[aria-hidden=false]+.b-hot_spot-overlay {
    opacity: 1;
    transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s, visibility 0s;
    visibility: visible
}

.l-grid_layout.m-3_3_6 .b-hot_spot-popup,
.l-grid_layout.m-6_3_3 .b-hot_spot-popup,
.l-grid_layout.m-2_up .b-hot_spot-popup {
    width: 80%
}

.b-accordion {
    box-shadow: none
}

.b-accordion-header {
    margin-bottom: 20px
}

.b-progress_bar {
    background-color: #fff;
    display: block;
    margin: 0 auto;
    opacity: 0;
    padding-bottom: 38px;
    padding-top: 12px;
    position: sticky;
    transform: translateY(-2px);
    transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s;
    visibility: hidden;
    z-index: 4
}

.b-progress_bar.m-initialized {
    opacity: 1;
    visibility: visible
}

@media screen and (max-width: 767.9px) {
    .b-progress_bar.m-hide_sm {
        display: none
    }
}

.b-progress_bar-inner {
    margin: 0 auto;
    max-width: 1920px;
    padding-left: 40px;
    padding-right: 40px;
    position: relative
}

@media screen and (min-width: 768px)and (max-width: 1023.9px) {
    .b-progress_bar-inner {
        padding-left: 20px;
        padding-right: 20px
    }
}

@media screen and (max-width: 767.9px) {
    .b-progress_bar-inner {
        padding-left: 24px;
        padding-right: 24px
    }
}

.b-progress_bar-content {
    background-color: #f3f4f3;
    height: 2px;
    position: relative
}

.b-progress_bar-line {
    background-color: #604099;
    display: block;
    height: 2px;
    position: absolute;
    top: 0;
    width: 0
}

.b-progress_bar-dot {
    color: #f3f4f3;
    cursor: pointer;
    min-height: 24px;
    min-width: 24px;
    position: absolute;
    text-decoration: none;
    top: -12px;
    transform: translateX(-50%);
    transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) .2s
}

.b-progress_bar-dot::before {
    background-color: #f3f4f3;
    border-radius: 50%;
    content: "";
    height: 8px;
    left: 0;
    margin: auto;
    outline: 4px solid #fff;
    position: absolute;
    right: 0;
    top: 9px;
    transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) .2s;
    width: 8px
}

.b-progress_bar-dot.m-active {
    color: #000
}

.b-progress_bar-dot.m-active::before {
    background-color: #604099
}

@media screen and (min-width: 1024px) {
    .b-progress_bar-dot:hover {
        color: #000
    }

    .b-progress_bar-dot:hover::before {
        background-color: #604099
    }
}

.b-progress_bar-dot_name {
    display: inline-block;
    max-width: 140px;
    overflow: hidden;
    padding-top: 20px;
    text-overflow: ellipsis;
    transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) .4s;
    white-space: nowrap
}

@media screen and (max-width: 767.9px) {
    .b-progress_bar-dot_name {
        opacity: 0
    }
}

.b-progress_bar-dot.m-text_left .b-progress_bar-dot_name {
    margin-left: -8px;
    transform: translateX(50%)
}

@media screen and (max-width: 767.9px) {
    .b-progress_bar-dot:first-child .b-progress_bar-dot_name {
        opacity: 1
    }
}

@media screen and (max-width: 767.9px) {
    .b-progress_bar-dot.m-active .b-progress_bar-dot_name {
        opacity: 0
    }
}

@media screen and (max-width: 767.9px) {
    .b-progress_bar-dot.m-last_active .b-progress_bar-dot_name {
        opacity: 1
    }
}

.b-progress_bar.m-hide_labels .b-progress_bar-dot_name {
    display: none
}

.b-page_review-message {
    font-weight: 500;
    padding: 16px 20px;
    text-align: center
}

.b-page_review-message svg {
    margin-right: 10px
}

.b-page_review-link {
    text-decoration: underline
}

.b-search_form.m-horizontal_left,
.b-search_form.m-horizontal_center,
.b-search_form.m-horizontal_right {
    display: flex;
    flex-wrap: wrap;
    width: 100%
}

.b-search_form.m-horizontal_left {
    justify-content: flex-start
}

.b-search_form.m-horizontal_center {
    justify-content: center
}

.b-search_form.m-horizontal_right {
    justify-content: flex-end
}

.b-search_form.m-vertical_top {
    align-self: flex-start
}

.b-search_form.m-vertical_middle {
    align-self: center
}

.b-search_form.m-vertical_bottom {
    align-self: flex-end
}

.b-search_form-content {
    /* max-width: var(--max-width, 100%); */
    width: 100%
}

@media screen and (max-width: 767.9px) {
    .b-search_form-content {
        max-width: 100%
    }
}

.b-search_form .b-header_search-wrap {
    margin-bottom: 36px
}

.b-search_form .b-header_search-title_no_results {
    font-size: 26px;
    font-weight: 136;
    letter-spacing: .01em;
    line-height: 32px;
    text-align: start;
    word-break: break-word
}

@media screen and (max-width: 1023.9px) {
    .b-search_form .b-header_search-title_no_results {
        font-size: 24px;
        letter-spacing: .002em;
        line-height: 30px
    }
}

.b-search_form .b-header_search-suggestion {
    font-size: 16px;
    margin-top: 16px
}

.b-search_form .b-header_search-keywords_wrap {
    display: inline-block
}

.b-product_attributes-empty {
    background-color: #f3f4f3;
    font-size: 18px;
    font-weight: 600;
    line-height: 2;
    padding: 100px 0;
    text-align: center;
    text-transform: uppercase
}

.b-product_attributes-empty .b-message {
    background-color: rgba(0, 0, 0, 0);
    margin: 0;
    padding: 0;
    text-transform: initial
}

.b-product_attribute_content {
    width: 100%
}

.b-product_attribute_content-title {
    background-color: #535353;
    font-size: 12px;
    padding: 4px;
    text-align: center
}

.b-carousel-item.m-duplicate_attribute .b-product_attribute_content-title,
.b-product_attributes_gallery-item.m-duplicate_attribute .b-product_attribute_content-title {
    background-color: #ffdddc;
    color: #8c0043
}

.b-product_attributes_gallery-title {
    margin-bottom: 40px
}

@media screen and (max-width: 767.9px) {
    .b-product_attributes_gallery-title {
        margin-bottom: 20px
    }
}

.b-product_attributes_gallery-main {
    display: flex;
    flex-wrap: wrap
}

@media screen and (min-width: 768px) {
    .b-product_attributes_gallery-main {
        margin: 0 -10px
    }
}

@media screen and (min-width: 768px) {
    .b-product_attributes_gallery-item {
        padding: 0 10px
    }
}

@media screen and (max-width: 767.9px) {
    .b-product_attributes_gallery-item {
        width: 100%
    }
}

@media screen and (min-width: 768px) {
    .b-product_attributes_gallery-item.m-md_6 {
        flex: 0 0 100%;
        max-width: 100%;
        min-width: 100%
    }
}

@media screen and (min-width: 768px) {
    .b-product_attributes_gallery-item.m-md_5 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
        min-width: 83.3333333333%
    }
}

@media screen and (min-width: 768px) {
    .b-product_attributes_gallery-item.m-md_4 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
        min-width: 66.6666666667%
    }
}

@media screen and (min-width: 768px) {
    .b-product_attributes_gallery-item.m-md_3 {
        flex: 0 0 50%;
        max-width: 50%;
        min-width: 50%
    }
}

@media screen and (min-width: 768px) {
    .b-product_attributes_gallery-item.m-md_2 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
        min-width: 33.3333333333%
    }
}

@media screen and (min-width: 768px) {
    .b-product_attributes_gallery-item.m-md_1 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
        min-width: 16.6666666667%
    }
}

@media screen and (min-width: 1024px) {
    .b-product_attributes_gallery-item.m-lg_12 {
        flex: 0 0 100%;
        max-width: 100%;
        min-width: 100%
    }
}

@media screen and (min-width: 1024px) {
    .b-product_attributes_gallery-item.m-lg_11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
        min-width: 91.6666666667%
    }
}

@media screen and (min-width: 1024px) {
    .b-product_attributes_gallery-item.m-lg_10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
        min-width: 83.3333333333%
    }
}

@media screen and (min-width: 1024px) {
    .b-product_attributes_gallery-item.m-lg_9 {
        flex: 0 0 75%;
        max-width: 75%;
        min-width: 75%
    }
}

@media screen and (min-width: 1024px) {
    .b-product_attributes_gallery-item.m-lg_8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
        min-width: 66.6666666667%
    }
}

@media screen and (min-width: 1024px) {
    .b-product_attributes_gallery-item.m-lg_7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
        min-width: 58.3333333333%
    }
}

@media screen and (min-width: 1024px) {
    .b-product_attributes_gallery-item.m-lg_6 {
        flex: 0 0 50%;
        max-width: 50%;
        min-width: 50%
    }
}

@media screen and (min-width: 1024px) {
    .b-product_attributes_gallery-item.m-lg_5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
        min-width: 41.6666666667%
    }
}

@media screen and (min-width: 1024px) {
    .b-product_attributes_gallery-item.m-lg_4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
        min-width: 33.3333333333%
    }
}

@media screen and (min-width: 1024px) {
    .b-product_attributes_gallery-item.m-lg_3 {
        flex: 0 0 25%;
        max-width: 25%;
        min-width: 25%
    }
}

@media screen and (min-width: 1024px) {
    .b-product_attributes_gallery-item.m-lg_2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
        min-width: 16.6666666667%
    }
}

@media screen and (min-width: 1024px) {
    .b-product_attributes_gallery-item.m-lg_1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
        min-width: 8.3333333333%
    }
}